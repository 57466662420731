import { Component, AfterViewInit } from '@angular/core';
import {User} from '../models/user';
import {UserService} from '../services/user.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';

declare var $: any;

@Component({
  selector: 'app-people',
  templateUrl: '../templates/people.component.html',
  styleUrls: ['../sass/people.component.scss'],
  providers: [UserService]
})
export class PeopleComponent implements AfterViewInit {
  public users: User[];
  public currentUser: User;
  dashboardSettings;

  public filterQuery = '';
  public rowsOnPage = 100;
  public sortBy = 'name';
  public sortOrder = 'asc';

  constructor(private _router: Router,
              private _localStorage: CoolLocalStorage,
              private _translate: TranslateService,
              private _userService: UserService) {
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    this.currentUser = JSON.parse(_localStorage.getItem('currentUser'));
    _translate.use(this.dashboardSettings.corporateSettings.locale);
    this._userService.getCorporateUsers(this.currentUser.businessClientId, '').subscribe(users => {
      this.users = users;
    });
  };

  ngAfterViewInit() {

  }

  openUser(userId: number) {
    this._router.navigate([`/person/${userId}`]);
  }
}
