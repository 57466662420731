export class FieldStatus {
  valid: boolean;
  message: string;

  constructor(valid: boolean = true,
              message: string = '') {
    this.valid = valid;
    this.message = message;
  }
}
