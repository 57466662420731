import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "dataFilter"
})
export class DataFilterPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if( query ) {
            if (!array) return [];
            return array.filter(item => {
                for( let key in item ) {
                    if( typeof item[key] === 'string' || 
                        typeof item[key] === 'number' || 
                        item[key] instanceof String) {
                        
                            let str = ( typeof item[key] === 'number' ? item[key].toString() : item[key] );
    
                            if( str.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
                                key != 'depLat' && 
                                key != 'depLng' && 
                                key != 'arrLat' && 
                                key != 'arrLng' ) {
                                    return true;
                            }
                    }
                }
            });
        } else {
            return array;
        }
    }
}