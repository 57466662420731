import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { CoolLocalStorage } from '@angular-cool/storage';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
  headers = null;
  constructor(private http: HttpClient, private localStorage: CoolLocalStorage) {
    this.headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authentication', 'F6G8znpFVgu')
      .set('X-Api-Key', 'F6G8znpFVgu');
  }

  getSettings(corporateHash: string) {
    return this.http.post(environment.apiUrl + '/getCorporateConfig', { corporateHash }, { headers: this.headers });
  }

  getCompanySettings(companyId: string) {
    return this.http.post(environment.apiUrl + '/getCompanyConfig', { companyId }, { headers: this.headers });
  }

  logout() {
    // remove user from local storage to log user out
    // this.currentUser  = {};
    this.localStorage.removeItem('currentUser');
    this.localStorage.removeItem('id_token');
    this.localStorage.removeItem('companyId');
  }

  login( businessClientId: number, email: string, password: string, companyId?: string ): Observable<User> {
    const data = { businessClientId, email, password, companyId };
    return this.http.post<User>(
      environment.apiUrl + (companyId === '' ? '/loginCorporateUser' : '/loginCorporateUserByCompany'),
      data,
      { headers: this.headers }
    );
  }

  getUserIp(): Observable<any> {
    return this.http.get('/ip.php');
  }

  loginByIp( ip, checksum, companyId: any ): Observable<User> {
    const data = { ip, checksum, companyId };
    return this.http.post<User>(environment.apiUrl + '/loginCorporateUserByIp', data, { headers: this.headers });
  }

  reset( businessClientId: number, email: string, companyId?: string  ): Observable<User> {
    const data = { businessClientId, email, companyId };
    return this.http.post<User>(
      environment.apiUrl + (companyId === '' ? '/resetCorporateUser' : '/resetCorporateUserByCompany'),
      data,
      { headers: this.headers }
    );
  }
}
