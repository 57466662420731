import {Component, AfterViewInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {UserService} from '../services/user.service';
import {User} from '../models/user';
import {DashboardService} from '../services/dashboard.service';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../services/utility.service';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-person-detail',
  templateUrl: '../templates/person-detail.component.html',
  styleUrls: ['../sass/person-detail.component.scss'],
  providers: [UserService, DashboardService]
})
export class PersonDetailComponent implements AfterViewInit {
  private userId: number;

  public formInputGenders: any;
  public formInputAccessType: any;
  public userSubmitted = false;
  public departments = [];
  currentUser: User;
  dashboardSettings;
  public user: User;

  constructor(
    private _router: Router,
    private _userService: UserService,
    private _localStorage: CoolLocalStorage,
    private _translate: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private _dashboardService: DashboardService) {
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    this.currentUser = JSON.parse(_localStorage.getItem('currentUser'));
    _translate.use(this.dashboardSettings.corporateSettings.locale);

    const params: any = this._activatedRoute.snapshot.params;
    this.userId = params.id;
    this.user = {
      businessClientId: this.currentUser.businessClientId,
      gender: 'male',
      type: 'department',
      loginAllowed: 'true',
    };
    _translate.get(['passenger', 'department_manager', 'admin', 'personal']).subscribe(translations => {
      // {'value': 'passenger', 'tag': UtilityService.ucFirst(translations.passenger)},

      if (this.currentUser.scope.type === 'admin') {
        this.formInputAccessType = [
          {value: 'department', tag: UtilityService.ucFirst(translations.department_manager)},
          {value: 'admin', tag: UtilityService.ucFirst(translations.admin)},
          {value: 'personal', tag: UtilityService.ucFirst(translations.personal)}
        ];
      }
      if (this.currentUser.scope.type === 'department') {
        // {'value': 'passenger', 'tag': UtilityService.ucFirst(translations.passenger)},
        this.formInputAccessType = [
          {value: 'department', tag: UtilityService.ucFirst(translations.department_manager)},
        ];
      }
    });

    _translate.get(['mr', 'mrs']).subscribe(translations => {
      this.formInputGenders = [{value: 'male', tag: translations.mr}, {value: 'female', tag: translations.mrs}];
    });

    this._dashboardService.getDepartments(this.currentUser.businessClientId).subscribe(department => {
      this.departments = department;
      if (!this.user.departmentId) {
        this.user.departmentId = this.departments[0].id;
      }
    });

    if (this.userId > 0) {
      this.loadUser(this.userId);
    }
  }

  loadUser(id: number) {
    this._userService.getCorporateUser(id).subscribe(user => {
      this.user = user;
    });
  }

  submitUser() {
    this.userSubmitted = true;
    this._userService.upsertCorporateUser(this.user).subscribe(user => {
      this.user = user;
      const self = this;
      self._translate.get(['person_created_title', 'person_created_text']).subscribe(translations => {
        swal({
          title: UtilityService.ucFirst(translations.person_created_title),
          text: UtilityService.ucFirst(translations.person_created_text).formatUnicorn(name),
          type: 'success',
          confirmButtonText: 'Ok',
          html: true
        },
        function(){
          self._router.navigate(['/people']);
        });
      });
    });
  }

  deleteUser(id: number, name: string) {
    this.userSubmitted = true;
    const self = this;

    self._translate.get([
      'delete_person_title',
      'delete_person_text',
      'delete_person_cancel_button',
      'delete_person_confirm_button'
    ]).subscribe(t => {
      swal({
        title: UtilityService.ucFirst(t.delete_person_title),
        text: UtilityService.ucFirst(t.delete_person_text).formatUnicorn(name),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: UtilityService.ucFirst(t.delete_person_cancel_button),
        confirmButtonText: UtilityService.ucFirst(t.delete_person_confirm_button),
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        html: true
      },
      function() {
        self._userService.deleteCorporateUser(id).subscribe(() => {
          self._translate.get(['person_deleted_title', 'person_deleted_text']).subscribe(tt => {
            swal({
              title: UtilityService.ucFirst(tt.person_deleted_title),
              text: UtilityService.ucFirst(tt.person_deleted_text).formatUnicorn(name),
              type: 'success',
              confirmButtonText: 'Ok',
              html: true
            },
            function() {
              self._router.navigate(['/people']);
            });
          });
        });
      });
    });
  }

  ngAfterViewInit() {
    const field = $('.field', 'form');
    const fieldTrigger = $('.trigger', field);

    fieldTrigger.on('click', function(){
      $(this).parent().toggleClass('active');
    });
  }
}
