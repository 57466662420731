import {Component, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../models/user';
import {FavoriteService} from '../services/favorite.service';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../services/utility.service';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-favorites',
  templateUrl: '../templates/favorites.component.html',
  styleUrls: ['../sass/favorites.component.scss'],
  providers: [FavoriteService]
})
export class FavoritesComponent implements AfterViewInit {
  public filterQuery = '';
  currentUser: User;
  dashboardSettings;
  favorites: any[] = [];

  constructor(private _router: Router,
              private _favoriteService: FavoriteService,
              private _localStorage: CoolLocalStorage,
              private _translate: TranslateService) {
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    this.currentUser = JSON.parse(_localStorage.getItem('currentUser'));
    _translate.use(this.dashboardSettings.corporateSettings.locale);
    this.loadFavorites();
  }

  loadFavorites() {
    const self = this;
    this._favoriteService.getFavorites(this.currentUser.businessClientId).subscribe(favorites => {
      self.favorites = favorites;
    });
  }

  ngAfterViewInit() {
    resizeTable();

    $(window).on('resize', function(){
      resizeTable();
    });

    function resizeTable() {
      const baseOffset = 65;
      const totalOffset = ( baseOffset + $('.search').innerHeight() );
      const table = $('.table');

      if (table.innerHeight() > ($(window).innerHeight() - totalOffset)) {
        table.css('height', ($(window).innerHeight() - totalOffset) + 'px');
      }
    }
  }

  removeFavorite(id: number, name: string) {
    const self = this;
    self._translate.get([
      'delete_address_title',
      'delete_address_text',
      'delete_address_cancel_button',
      'delete_address_confirm_button'
    ]).subscribe(t => {
      swal({
        title: UtilityService.ucFirst(t.delete_address_title),
        text: UtilityService.ucFirst(t.delete_address_text).formatUnicorn(name),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: UtilityService.ucFirst(t.delete_address_cancel_button),
        confirmButtonText: UtilityService.ucFirst(t.delete_address_confirm_button),
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        html: true
      },
      function(){
        self._favoriteService.deleteFavorite(id, self.currentUser.businessClientId).subscribe(() => {
          self._translate.get(['address_deleted_title', 'address_deleted_text']).subscribe(tt => {
            swal({
              title: UtilityService.ucFirst(tt.address_deleted_title),
              text: UtilityService.ucFirst(tt.address_deleted_text).formatUnicorn(name),
              type: 'success',
              confirmButtonText: 'Ok',
              html: true
            },
            function(){
              self.loadFavorites();
            });
          });
        }, error => {
          self._translate.get(['address_deleted_error_title', 'address_deleted_error_text']).subscribe(ttt => {
            swal({
              title: UtilityService.ucFirst(ttt.address_deleted_error_title).formatUnicorn(error.status),
              text: UtilityService.ucFirst(ttt.address_deleted_error_text),
              type: 'error',
              confirmButtonText: 'Ok',
              html: true
            },
            function(){
            });
        });
        });
      });
    });
  }
}
