import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPrice'
})
export class FormatPricePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    try {
      return parseFloat(value).toFixed(2);
    } catch (e) {
      return 0;
    }
  }

}
