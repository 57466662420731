import {Component, AfterViewInit, OnDestroy} from '@angular/core';
import {RidesService} from '../services/rides.service';
import Timer = NodeJS.Timer;
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../services/utility.service';

declare var $: any;
declare var flatpickr: any;
declare var swal: any;

@Component({
  selector: 'app-export-rides',
  templateUrl: '../templates/export-rides.component.html',
  styleUrls: ['../sass/export-rides.component.scss'],
  providers: [RidesService]
})
export class ExportRidesComponent implements AfterViewInit, OnDestroy {
  private validateInterval: Timer;
  exportRidesModalId = 'export-rides-modal';
  today = new Date();
  firstDay = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
  lastDay = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0);
  currentUser;
  dashboardSettings;
  exportData: any;
  exportSettings: any = {};

  constructor (private _translate: TranslateService,
               private _localStorage: CoolLocalStorage,
               private _rideService: RidesService) {
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    this.currentUser = JSON.parse(_localStorage.getItem('currentUser'));
    _translate.use(this.dashboardSettings.corporateSettings.locale);
    this.exportSettings = {
      companyId: this.dashboardSettings.companySettings.companyId,
      businessClientId: this.currentUser.businessClientId,
      dateRange: [],
      startRange: '',
      endRange: ''
    };
  }

  onSubmit() {
    const self = this;
    $('#' + self.exportRidesModalId).prop('checked', false).change();


    this._translate.get(['export_loading_title', 'export_loading_text']).subscribe(exportTranslations => {
      swal({
        title: UtilityService.ucFirst(exportTranslations.export_loading_title),
        text: UtilityService.ucFirst(exportTranslations.export_loading_text),
        imageUrl: 'assets/images/swal_loader.gif',
        showConfirmButton: false
      });

      self._rideService.generateExport(this.exportSettings).subscribe(exportData => {
        self.exportData = exportData;
        self.validateInterval = setInterval(function () {
          self._rideService.validateExport(self.exportData.exportId).subscribe(validationResult => {

            clearInterval(self.validateInterval);
            const translations = ['export_completed_title', 'export_completed_text', 'export_completed_download'];
            self._translate.get(translations).subscribe(completedTranslations => {
              swal({
                  title: UtilityService.ucFirst(completedTranslations.export_completed_title),
                  text: UtilityService.ucFirst(completedTranslations.export_completed_text),
                  type: 'success',
                  confirmButtonText: UtilityService.ucFirst(completedTranslations.export_completed_download)
                },
                function () {
                  window.open(validationResult.file);
                });
            });
          });
        }, 2000);
      });
    });
  }

  ngAfterViewInit() {
    const self = this;
    $('.export-rides-trigger').on('click', function(){
      $('#' + self.exportRidesModalId).prop('checked', true).change();
    });
    const calendar = flatpickr('#dateRange', {
      dateFormat: 'd-m-Y',
      mode: 'range',
      weekNumbers: true,
      onChange: function (selectedDates, dateStr, instance) {
        if (selectedDates.length >= 2) {
          self.exportSettings.dateRange = selectedDates;
          self.exportSettings.startRange = selectedDates[0];
          self.exportSettings.endRange = selectedDates[1];
          instance.close();
        }
      }
    });

    setTimeout(function(){
      // console.log('setting dates');
      calendar.setDate([
        self.firstDay,
        self.lastDay
      ], true);
    }, 1500);
  }

  ngOnDestroy() {
    if (this.validateInterval) {
      clearInterval(this.validateInterval);
    }
  }
}
