import {Component, HostBinding} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  providers: []
})

export class AppComponent {
  /**
   * Set a body class based on the current location
   */
  @HostBinding('class') get bodyClass() {
    const name = this._location.path().replace(/[\W_]/g, '-');
    return (name === '' ? 'root' : (name.substr(0, 1) === '-' ? name.substr(1, name.length) : name));
  }

  constructor(private _location: Location) {}
}
