import {Component, AfterViewInit, Input, OnInit} from '@angular/core';
import {User} from '../models/user';
import {UUID} from 'angular2-uuid';
import {UserService} from '../services/user.service';
import {Ride} from '../models/ride';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import { RideComponent } from './ride.component';

declare var $: any;

@Component({
  selector: 'app-passenger-field-form',
  templateUrl: '../templates/passengers-field-form.component.html',
  styleUrls: ['../sass/passengers-field-form.component.scss'],
  providers: [UserService]
})
export class PassengersFieldFormComponent implements AfterViewInit, OnInit {
  @Input() index = 0;
  @Input() disabled = false;
  @Input() id = UUID.UUID();
  @Input() ride: Ride;
  @Input() value = '';
  @Input() modalId = '';
  @Input() passengersText;

  @Input() firstNameId = UUID.UUID();
  @Input() lastNameId = UUID.UUID();
  @Input() phoneNumberId = UUID.UUID();
  @Input() emailId = UUID.UUID();
  @Input() employeeId = UUID.UUID();

  public passenger: any = {};
  passengers: any[] = [{}];
  currentUser: User;
  dashboardSettings: any;
  nameMandatoryMarker: string;
  phoneMandatoryMarker: string;
  formType = 'default';
  @Input() parent!: RideComponent;

  constructor(private _userService: UserService,
              private _localStorage: CoolLocalStorage,
              private _translate: TranslateService) {
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    this.formType = this.dashboardSettings.orderSettings.formType;
    this.currentUser = JSON.parse(_localStorage.getItem('currentUser'));
    _translate.use(this.dashboardSettings.corporateSettings.locale);

    if (this.dashboardSettings.orderSettings.mandatoryName) {
      this.nameMandatoryMarker = '*';
    }

    if (this.dashboardSettings.orderSettings.mandatoryPhoneNumber) {
      this.phoneMandatoryMarker = '*';
    }

    _userService.getCorporateUsers(this.currentUser.businessClientId, (this.currentUser.scope && this.currentUser.scope.type !== 'personal' ? '' : 'personal')).subscribe((passengers: any[]) => {
      const firstNameData: any = [];
      const lastNameData: any = [];
      const phoneNumberData: any = [];
      const emailData: any = [];
      const employeeData: any = [];

      if (passengers[0] && passengers[0].type === 'personal') {
        this.passenger = passengers[0];
        $('#passenger-form input').attr('disabled',true);
      }

      passengers.forEach(function(item, i){
        firstNameData.push({
          'value': item.firstName + ' ' + item.lastName,
          'label': item.firstName + ' ' + item.lastName,
          'item': item,
          'index': i
        });
        lastNameData.push({
          'value' : item.firstName + ' ' + item.lastName,
          'item' : item,
          'index' : i
        });
        phoneNumberData.push({
          'value': item.phoneNumber,
          'item': item,
          'index': i
        });
        emailData.push({
          'value': item.email,
          'item': item,
          'index': i
        });
        employeeData.push({
          'value': item.employeeNr,
          'item': item,
          'index': i
        });
      });

      this.passengers = passengers;
      $('input', '#' + this.firstNameId).autocomplete('option', { source: firstNameData });
      $('input', '#' + this.lastNameId).autocomplete('option', { source: lastNameData });
      $('input', '#' + this.phoneNumberId).autocomplete('option', { source: phoneNumberData });
      $('input', '#' + this.emailId).autocomplete('option', { source: emailData });
      $('input', '#' + this.employeeId).autocomplete('option', { source: employeeData });
    });
  };

  ngOnInit() {
    if (typeof this.ride.passengerArray !== 'undefined') {
      this.passenger = Object.assign({}, this.ride.passengerArray[0]);
    }
  }

  ngAfterViewInit() {
    const self = this;
    const field = $('.field', 'form');
    const fieldTrigger = $('.trigger', field);

    fieldTrigger.on('click', function(){
      $(this).parent().toggleClass('active');
    });

    /**
     * FirstName autoComplete
     */
    $('input', '#' + this.firstNameId).autocomplete({
      deplay: 0,
      source: this.passengers,
      select: function (event: any, ui: any) {
        self.passenger = Object.assign({}, self.passengers[ui.item.index]);
        self.value = ui.item.value;
      }
    }).autocomplete('instance')._renderItem = function (ul: any, item: any) {
      const html = '<div><strong>' + item.item.firstName + '</strong> ' + item.item.lastName + '</div>';
      return $('<li class="' + item.type + '">').append(html).appendTo(ul);
    };

    /**
     * LastName autoComplete
     */
    $('input', '#' + this.lastNameId).autocomplete({
      deplay: 0,
      source: this.passengers,
      select: function (event: any, ui: any) {
        self.passenger = Object.assign({}, self.passengers[ui.item.index]);
        self.value = ui.item.value;
      }
    }).autocomplete('instance')._renderItem = function (ul: any, item: any) {
      const html = '<div>' + item.item.firstName + ' ' + item.item.lastName + '</div>';
      return $('<li class="' + item.type + '">').append(html).appendTo(ul);
    };

    /**
     * phoneNumber autoComplete
     */
    $('input', '#' + this.phoneNumberId).autocomplete({
      deplay: 0,
      source: this.passengers,
      select: function (event: any, ui: any) {
        self.passenger = Object.assign({}, self.passengers[ui.item.index]);
        self.value = ui.item.value;
      }
    }).autocomplete('instance')._renderItem = function (ul: any, item: any) {
      const html = '<div> <strong> ' + item.item.phoneNumber + '</strong> - ' + item.item.firstName + ' ' + item.item.lastName + '</div>';
      return $('<li class="' + item.type + '">').append(html).appendTo(ul);
    };

    /**
     * Email autoComplete
     */
    $('input', '#' + this.emailId).autocomplete({
      deplay: 0,
      source: this.passengers,
      select: function (event: any, ui: any) {
        self.passenger = Object.assign({}, self.passengers[ui.item.index]);
        self.value = ui.item.value;
      }
    }).autocomplete('instance')._renderItem = function (ul: any, item: any) {
      const html = '<div> <strong> ' + item.item.email + '</strong> - ' + item.item.firstName + ' ' + item.item.lastName + '</div>';
      return $('<li class="' + item.type + '">').append(html).appendTo(ul);
    };

    /**
     * Employee autoComplete
     */
    if (this.formType !== 'airport') {
      $('input', '#' + this.employeeId).autocomplete({
        deplay: 0,
        source: this.passengers,
        select: function (event: any, ui: any) {
          self.passenger = Object.assign({}, self.passengers[ui.item.index]);
          self.value = ui.item.value;
        }
      }).autocomplete('instance')._renderItem = function (ul: any, item: any) {
        const html = '<div> <strong> ' + item.item.employeeNr + '</strong> - ' + item.item.firstName + ' ' + item.item.lastName + '</div>';
        return $('<li class="' + item.type + '">').append(html).appendTo(ul);
      };
    }
  }

  savePassengers() {
    this.ride.passengerArray = [];
    this.ride.passengerArray.push(this.passenger);
    $('#' + this.modalId).prop('checked', false).change();
    this.parent.validateForm()
  }
}
