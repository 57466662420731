import {Component, AfterViewInit} from '@angular/core';
import {Department} from '../models/department';
import {Router} from '@angular/router';
import {DepartmentService} from '../services/department.service';
import {User} from '../models/user';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';

declare var $: any;

@Component({
  selector: 'app-departments',
  templateUrl: '../templates/departments.component.html',
  styleUrls: ['../sass/departments.component.scss'],
  providers: [DepartmentService]
})
export class DepartmentsComponent implements AfterViewInit {
  public filterQuery = '';
  departments: Department[] = [];
  currentUser: User;
  dashboardSettings: any;

  constructor(private _router: Router,
              private _translate: TranslateService,
              private _localStorage: CoolLocalStorage,
              private _departmentService: DepartmentService) {
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    this.currentUser = JSON.parse(_localStorage.getItem('currentUser'));
    _translate.use(this.dashboardSettings.corporateSettings.locale);
    _departmentService.getDepartments(this.currentUser.businessClientId).subscribe(department => {
      this.departments = department;
    });
  };

  ngAfterViewInit() {

  }

  openDepartment (id: number) {
    this._router.navigate([`/department/${id}`]);
  }
}
