import {Component, AfterViewInit} from '@angular/core';
import {RidesService} from '../services/rides.service';
import {Router} from '@angular/router';
import {User} from '../models/user';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {DashboardService} from "../services/dashboard.service";

declare var $: any;

@Component({
  selector: 'app-rides',
  templateUrl: '../templates/rides.component.html',
  styleUrls: ['../sass/rides.component.scss'],
  providers: [RidesService, DashboardService]
})
export class RidesComponent implements AfterViewInit {
  public hasRides = false;
  public rides: any[];
  public ridesGrouped: any[];
  public total: any = {
    currency: '€',
    amount: 0
  };
  public bgColor = 'odd';
  public groupedTotal: any = {};
  public filterQuery = '';
  currentUser: User;
  subaccounts = [];
  subaccountFilter = 0;
  needConnected = false;
  hasChildern = false;
  dashboardSettings: any;

  constructor (private _router: Router,
               private _translate: TranslateService,
               private _localStorage: CoolLocalStorage,
               private _dashboardService: DashboardService,
               private _ridesService: RidesService) {
    this.currentUser = JSON.parse(_localStorage.getItem('currentUser'));
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    _translate.use(this.dashboardSettings.corporateSettings.locale);
    this.getSubAccounts();
    this.getRides();
  }
  getSubAccounts() {
    this._dashboardService.getSubAccounts(this.currentUser.businessClientId).subscribe(subaccounts => {
      this.subaccounts = subaccounts;
    }, (error) => {
      /**
       * No active rides at the moment
       */
      if (error.status === 404) {

      }
    });
  }

  setFilter() {
    this.getRides();
  }

  getRides() {
    this._ridesService.getRides(this.currentUser.id, this.subaccountFilter, (this.currentUser.scope && this.currentUser.scope.type !== 'personal' ? this.currentUser.department.id : this.currentUser.scope.klantId), 'all').subscribe(rides => {
      this.rides = rides;
      let prevValue;

      this.rides.forEach((r, index) => {
        if(r.businessClientId !== this.currentUser.businessClientId) {
          this.hasChildern = true;
        }
        if(!this.total) {
          this.total = {};
        }

        if(r.currency && !this.total.currency) {
          this.total.currency = r.currency;
        }
        if(!r.fieldValue) {
          r.fieldValue = '';
        }
        if(r.amount && this.dashboardSettings.companySettings.hidePrices === 'n') {
          if (!this.groupedTotal[r.fieldValue]) {
            this.groupedTotal[r.fieldValue] = {
              currency: '€',
              amount: 0
            };
            if (r.fieldName) {
              this.rides[index].title = r.fieldName + ': ' + r.fieldValue;
            }
            if (index > 0) {
              this.rides[index - 1].groupedTotal = this.groupedTotal[prevValue];
            }
            this.bgColor = 'even';
          }

          this.groupedTotal[r.fieldValue].currency = r.currency;
          this.groupedTotal[r.fieldValue].amount = this.groupedTotal[r.fieldValue].amount + r.amount;
          this.groupedTotal[r.fieldValue].value = r.fieldValue;
        }
        prevValue = r.fieldValue;
        this.total.amount = this.total.amount + r.amount;
        if(r.connectedRideId) {
          this.needConnected = true;
        }
        this.bgColor = (this.bgColor==='odd' ? 'even' : 'odd');
        r.bgColor=this.bgColor;
      });
      this.hasRides = true;
    }, (error) => {
      /**
       * No active rides at the moment
       */
      if (error.status === 404) {

      }
    });
  }

  openRide(rideId: number) {
    this._router.navigate([`/ride/${rideId}`]);
  }

  ngAfterViewInit() {
    resizeTable();
    $(window).on('resize', function(){
      resizeTable();
    });

    function resizeTable() {
      const baseOffset = 65;
      const totalOffset = ( baseOffset + $('.search').innerHeight() );
      const table = $('.table');

      // Reset table height
      table.css('height', 'auto');

      // If the table is higher than the screen, set a height. Otherwise set auto height
      if ( table.innerHeight() > ( $(window).innerHeight() - totalOffset ) ) {
        table.css('height', ( $(window).innerHeight() - totalOffset ) + 'px');
      } else {
        table.css('height', 'auto');
      }
    }
  }
}
