import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {

  static notNullOrUndefined(value: any) {
    return (typeof value !== 'undefined' && value !== null && value !== 'null');
  }

  static ucFirst(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  static getLocationString(location: any) {
    if (!UtilityService.notNullOrUndefined(location)) return '';
    if (UtilityService.notNullOrUndefined(location.streetName) &&
      UtilityService.notNullOrUndefined(location.houseNumber) &&
      UtilityService.notNullOrUndefined(location.postalCode) &&
      UtilityService.notNullOrUndefined(location.city)) {
      return `${location.streetName} ${location.houseNumber}, ${location.postalCode} ${location.city}`;
    } else if(UtilityService.notNullOrUndefined(location.streetName) &&
      UtilityService.notNullOrUndefined(location.houseNumber) &&
      UtilityService.notNullOrUndefined(location.city)) {
      return `${location.streetName} ${location.houseNumber}, ${location.city}`;
    } else {
      return location.internationalAlias;
    }
  }

  constructor() { }

}
