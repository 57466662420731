import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../models/user';
import {Ride} from '../models/ride';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class RidesService {
  headers = null;
  user: User;
  ride: Ride;

  constructor(private http: HttpClient, private localStorage: CoolLocalStorage) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authentication', 'F6G8znpFVgu')
      .set('X-Api-Key', 'F6G8znpFVgu')
      .set('X-Hash-Key', this.user.id_token)
      .set('X-User-Key', '' + this.user.id + '')
      .set('X-Department-Key', '' + this.user.scope.departmentId + '');
  }


  getRide(id: number): Observable<Ride> {
    const data = {
      id,
      businessClientId: (this.user.businessClientId?this.user.businessClientId:this.user.id)
    };
    return this.http.post<Ride>(environment.apiUrl + '/getRide', data, { headers: this.headers });
  }

  getHashRide(id: number, hash: string): Observable<Ride> {
    const data = {
      id,
      hash
    };
    return this.http.post<Ride>(environment.apiUrl + '/getHashRide', data, { headers: this.headers });
  }

  getRides(id: number,subAccountId: number, departmentId: number, category: string): Observable<Ride[]> {
    const data = {
      subAccountId,
      departmentId,
      userId: id,
      statusCategory: category
    };
    return this.http.post<Ride[]>(environment.apiUrl + '/getRides', data, { headers: this.headers });
  }

  upsertRide(ride: Ride): Observable<Ride> {
    return this.http.post<Ride>(environment.apiUrl + '/upsertRides', ride, { headers: this.headers });
  }

  upsertReturnRide(data: any): Observable<any> {
    console.log(`[RidesService.upsertReturnRide]: data`, data);
    return this.http.post<Ride>(environment.apiUrl + '/upsertReturnTrip', data, { headers: this.headers });
  }

  generateExport(exportSettings: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/generateExport', exportSettings, { headers: this.headers });
  }

  validateOrderTime(date: string, companyId: string, hash: string): Observable<any> {
    const data = {
      companyId,
      requestedDate: date,
      corporateHash: hash
    };
    return this.http.post(environment.apiUrl + '/validateOrderTime', data, { headers: this.headers });
  }

  validateExport(exportId: number): Observable<any> {
    return this.http.post(environment.apiUrl + '/validateExport', { exportId }, { headers: this.headers });
  }

  calculateRidePrice(ride: Ride, hash: string): Observable<any> {
    const data:any = ride;
    data.corporateHash = hash;
    return this.http.post(environment.apiUrl + '/calculateRidePrice', ride, { headers: this.headers });
  }

  calculateRideQuotation(data: any, hash: string): Observable<any> {
    data.corporateHash = hash;
    return this.http.post(environment.apiUrl + '/calculateQuotation', data, { headers: this.headers });
  }

  getMinRequestDate(companyId, hash): Observable<any> {
    return this.http.post(environment.apiUrl + '/getMinRequestDate', {
      companyId,
      corporateHash: hash
    }, { headers: this.headers });
  }

  cancelRide(companyId, rideId): Observable<any> {
    return this.http.post(environment.apiUrl + '/cancelRide', {
      companyId,
      rideId
    }, { headers: this.headers });
  }
}
