import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Department} from '../models/department';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class DashboardService {
    headers = new HttpHeaders();
    dashboardSettings: object = {};

    constructor(private _http: HttpClient, private _localStorage: CoolLocalStorage) {
      this.headers = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('Authentication', 'F6G8znpFVgu')
        .set('X-Api-Key', 'F6G8znpFVgu');
      this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    };

    getAvailableTaxis (businessClientId: number): Observable<any> {
      const data = { businessClientId: businessClientId };
      return this._http.post(environment.apiUrl + '/getAvailableTaxis', data, { headers: this.headers });
    }

  getSubAccounts (businessClientId: number): Observable<Department[]> {
    const data = { businessClientId: businessClientId };
    return this._http.post<Department[]>(environment.apiUrl + '/getSubAccounts', data, { headers: this.headers });
  }

  getDepartments (businessClientId: number): Observable<Department[]> {
    const data = { businessClientId: businessClientId };
    return this._http.post<Department[]>(environment.apiUrl + '/getDepartments', data, { headers: this.headers });
  }
}
