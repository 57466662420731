import {Directive, DoCheck, Input} from '@angular/core';
import {GoogleMapsAPIWrapper} from '@agm/core';

declare var google: any;

@Directive({
    selector: 'agm-custom-directions'
})
export class DirectionsMapDirective implements DoCheck {
    @Input() origin: any;
    @Input() destination: any;
    locationChangeComparison = '';

    constructor(private gmapsApi: GoogleMapsAPIWrapper) {
    }

    ngDoCheck() {
        if (this.destination.lat !== 0) {
            const self = this;
            if (this.locationChangeComparison !== DirectionsMapDirective.generateLocationHash({
                o: self.origin,
                d: self.destination
            })) {
                this.locationChangeComparison = DirectionsMapDirective.generateLocationHash({
                    o: self.origin,
                    d: self.destination
                });

                this.gmapsApi.getNativeMap().then(map => {
                    const directionsService = new google.maps.DirectionsService;
                    const directionsDisplay = new google.maps.DirectionsRenderer;
                    directionsDisplay.setMap(map);
                    directionsDisplay.setOptions({suppressMarkers: true});
                    directionsService.route({
                        origin: {lat: self.origin.lat, lng: self.origin.lng},
                        destination: {lat: self.destination.lat, lng: self.destination.lng},
                        waypoints: [],
                        optimizeWaypoints: true,
                        travelMode: 'DRIVING'
                    }, function (response: any, status: any) {
                        if (status === 'OK') {
                            directionsDisplay.setDirections(response);
                        } else {
                            console.log('Directions request failed due to ' + status);
                        }
                    });
                })
            }
        }
    }

    ngOnInit() {
    }

    static generateLocationHash(array: any) {
        return JSON.stringify(array);
    }
}
