import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Ride} from '../models/ride';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Complaint} from '../models/complaint';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class ComplaintService {
  headers = null;
  ride: Ride;
  complaint: Complaint;
  businessClientId = '';

  constructor(private _http: HttpClient, private _localStorage: CoolLocalStorage) {
    this.headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authentication', 'F6G8znpFVgu')
      .set('X-Api-Key', 'F6G8znpFVgu');
    this.businessClientId = JSON.parse(_localStorage.getItem('businessClientId'));
  };

  getComplaint(rideId: number): Observable<Complaint> {
    return this._http.post<Complaint>(environment.apiUrl + '/getComplaint', { rideId }, { headers: this.headers });
  }

  getComplaints(departmentId: number): Observable<any> {
    const data = { departmentId: departmentId };
    return this._http.post<any>(environment.apiUrl + '/getComplaints', data, { headers: this.headers });
  }

  upsertComplaints(complain: Complaint): Observable<Ride> {
    return this._http.post<Ride>(environment.apiUrl + '/upsertComplaints', complain, { headers: this.headers });
  }
}
