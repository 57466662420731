import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Ride} from '../models/ride';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Address} from '../models/address';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class RouteService {
  headers = null;
  route: Address;
  user: '';

  constructor(private http: HttpClient, private localStorage: CoolLocalStorage) {
    this.headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authentication', 'F6G8znpFVgu')
      .set('X-Api-Key', 'F6G8znpFVgu');
    this.user = JSON.parse(localStorage.getItem('currentUser'));
  }

  getRoutes(businessClientId: number): Observable<any[]> {
    const data = { businessClientId };
    return this.http.post<any[]>(environment.apiUrl + '/getRoutes', data, { headers: this.headers });
  }

  upsertRoutes(businessClientId: number, route: Ride): Observable<any> {
    const data = { businessClientId, route };
    return this.http.post<any>(environment.apiUrl + '/upsertRoute', data, { headers: this.headers });
  }

  getRoute(businessClientId: number, routeId: number): Observable<string> {
    const data = { businessClientId, routeId };
    return this.http.post<string>(environment.apiUrl + '/getRoute', data, { headers: this.headers });
  }

  deleteRoute(businessClientId: number, id: number): Observable<any> {
    const data = { businessClientId, routeId: id };
    return this.http.post(environment.apiUrl + '/deleteRoute', data, { headers: this.headers });
  }
}
