import {Component, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../models/user';
import {RouteService} from '../services/route.service';
import {Ride} from '../models/ride';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../services/utility.service';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-routes',
  templateUrl: '../templates/routes.component.html',
  styleUrls: ['../sass/routes.component.scss'],
  providers: [RouteService]
})
export class RoutesComponent implements AfterViewInit {
  public filterQuery = '';
  currentUser: User;
  dashboardSettings: any;
  routes: Ride[] = [];

  constructor(private _router: Router,
              private _translate: TranslateService,
              private _localStorage: CoolLocalStorage,
              private _routeService: RouteService) {
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    this.currentUser = JSON.parse(_localStorage.getItem('currentUser'));
    _translate.use(this.dashboardSettings.corporateSettings.locale);
    this.loadRoutes();
  }

  ngAfterViewInit() {
    resizeTable();

    $(window).on('resize', function(){
      resizeTable();
    });

    function resizeTable() {
      const baseOffset = 65;
      const totalOffset = ( baseOffset + $('.search').innerHeight() );
      const table = $('.table');

      if (table.innerHeight() > ($(window).innerHeight() - totalOffset)) {
        table.css('height', ($(window).innerHeight() - totalOffset) + 'px');
      }
    }
  }

  loadRoutes() {
    const self = this;
    this._routeService.getRoutes(this.currentUser.businessClientId).subscribe(routes => {
      self.routes = [];
      routes.forEach(function(item){
        item.route = JSON.parse(item.route);
        item.route.routeId = item.routeId;
        item.route.departureAddress = item.route.addressArray[0].description;
        if (item.route.addressArray.length > 1) {
          item.route.arrivalAddress = item.route.addressArray[item.route.addressArray.length - 1].description;
        }
        if (typeof item.route.passengerArray !== 'undefined' && item.route.passengerArray.length > 0) {
          item.route.passengerName = item.route.passengerArray[0].firstName + ' ' + item.route.passengerArray[0].lastName;
        }

        self.routes.push(item.route);
      });
    });
  }

  removeRoute(routeId: number, name: string) {
    const self = this;
    self._translate.get([
      'delete_route_title',
      'delete_route_text',
      'delete_route_cancel_button',
      'delete_route_confirm_button'
    ]).subscribe(t => {
      swal({
        title: UtilityService.ucFirst(t.delete_route_title),
        text: UtilityService.ucFirst(t.delete_route_text).formatUnicorn(name),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: UtilityService.ucFirst(t.delete_route_cancel_button),
        confirmButtonText: UtilityService.ucFirst(t.delete_route_confirm_button),
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        html: true
      },
      function () {
        self._routeService.deleteRoute(self.currentUser.businessClientId, routeId).subscribe(() => {
          self._translate.get(['route_deleted_title', 'route_deleted_text']).subscribe(tt => {
            swal({
              title: UtilityService.ucFirst(tt.route_deleted_title),
              text: UtilityService.ucFirst(tt.route_deleted_text).formatUnicorn(name),
              type: 'success',
              confirmButtonText: 'Ok',
              html: true
            },
            function () {
              self.loadRoutes();
            });
          });

        }, error => {
          self._translate.get(['route_deleted_error_title', 'route_deleted_error_text']).subscribe(ttt => {
            swal({
              title: UtilityService.ucFirst(ttt.route_deleted_error_title).formatUnicorn(error.status),
              text: UtilityService.ucFirst(ttt.route_deleted_error_text),
              type: 'error',
              confirmButtonText: 'Ok',
              html: true
            },
            function () {
            });
          });
        });
      });
    });
  }

  newRide(routeId: number) {
    this._router.navigate([`/ride/new/${routeId}`]);
  }
}
