export class User {
    id?: number;
    gender: string;
    type: string;
    loginAllowed: string;
    businessClientId: number;
    departmentId?: number;
    email?: string;
    phoneNumber?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    functionTitle?: string;
    employeeNr?: string;
    id_token?: string;
    scope?: any;
    department?: any;
    remark?: any;
    hash?: any;
}
