import {Directive, DoCheck, Input} from '@angular/core';
import {Ride} from '../models/ride';
import {GoogleMapsAPIWrapper} from '@agm/core';

declare var google: any;

@Directive({
  selector: 'agm-custom-waypoints'
})
export class DirectionsMapWaypoints implements DoCheck {
  @Input() ride: Ride;

  currentAddressArrayLength = 1;

  addressArrayHash = '';

  directionsService: any = undefined;
  directionsDisplay: any = undefined;

  static generateLocationHash(array: any) {
    return JSON.stringify(array);
  }

  constructor (private gmapsApi: GoogleMapsAPIWrapper) {

  }

  drawRoute() {
    const self = this;
    if (typeof(google) === 'undefined' || !this.ride.addressArray) {
      setTimeout(() => { self.drawRoute(); }, 100);
      return false;
    }

    if (
      typeof(this.ride.addressArray[this.ride.addressArray.length - 1]) !== 'undefined' &&
      typeof(this.ride.addressArray[this.ride.addressArray.length - 1]).lat !== 'undefined' &&
      typeof(this.ride.addressArray[0].lat) !== 'undefined' &&
      typeof(this.ride.addressArray[1]) !== 'undefined'
    ) {
      if (typeof(this.directionsService) === 'undefined') {
        this.directionsService = new google.maps.DirectionsService;
      }

      if (typeof(this.directionsDisplay) === 'undefined') {
        this.directionsDisplay = new google.maps.DirectionsRenderer;
      }

      const waypointArray = [];
      if (this.ride.addressArray.length >= 2) {
        for (let i = 1; i < (this.ride.addressArray.length - 1); i++) {
          if (typeof(this.ride.addressArray[i].lat) !== 'undefined') {
            waypointArray.push({
              location: new google.maps.LatLng(this.ride.addressArray[i].lat, this.ride.addressArray[i].lng),
              stopover: false
            });
          }
        }

        this.currentAddressArrayLength = this.ride.addressArray.length;

        const self = this;

        const routeOptions: any = {
          origin: {
            lat: this.ride.addressArray[0].lat,
            lng: this.ride.addressArray[0].lng
          },
          destination: {
            lat: this.ride.addressArray[this.ride.addressArray.length - 1].lat,
            lng: this.ride.addressArray[this.ride.addressArray.length - 1].lng
          },
          travelMode: 'DRIVING'
        };

        if(waypointArray && waypointArray.length) {
          routeOptions.waypoints = waypointArray;
        }

        this.gmapsApi.getNativeMap().then(map => {
          self.directionsDisplay.setMap(map);
          self.directionsDisplay.setOptions( { suppressMarkers: true } );
          self.directionsService.route(routeOptions, function(response: any, status: any) {
            if (status === 'OK') {
              self.directionsDisplay.setDirections(response);

              self.ride.distance = 0;
              self.ride.duration = 0;
              const legs = response.routes[0].legs;
              for (let i = 0; i < legs.length; ++i) {
                self.ride.distance += legs[i].distance.value;
                self.ride.duration += legs[i].duration.value;
              }
            } else {
              console.log('Waypoint: Directions request failed due to ' + status);
            }
          });
        });
      }
    }
  }

  ngDoCheck(): void {
    const newAddressArrayHash = DirectionsMapWaypoints.generateLocationHash(this.ride.addressArray);
    if (this.addressArrayHash !== newAddressArrayHash) {
      this.addressArrayHash = newAddressArrayHash;
      // console.log(`[DirectionsMapWaypoints.ngDoCheck]: Address array changed, drawing new route`);
      this.drawRoute();
    }
  }
}
