export class Ride {
  id: number;
  businessClientId: number;
  routeId: number;
  departmentId: number;

  connectedRideId: number;
  connectedCancelAllow: boolean;
  firstInReturn: number;
  firstInReturnStatus: string;
  secondInReturnStatus: string;

  type: string;
  rating: number;
  complainDesc: string;
  status?: string;
  departureAddress: string;
  flightNumber: string;
  passengerCount: number;
  carTypeId: number;

  amount?: number;
  depLat: number;
  depLng: number;

  arrivalAddress?: string;
  arrLat?: number;
  arrLng?: number;
  addressArray: any;
  pickupDate?: any;
  pickupDateTime?: any;
  arrivalDate?: any;
  arrivalDateTime?: any;
  estimatedPickupTime?: any;
  passengers?: any;
  currency?: any;
  decAmount?: any;
  rideOrigin?: any;
  clientName?: any;
  clientPhone?: any;
  email?: any;
  remark?: any;

  requestedDate?: any;
  requestedDateType?: string;
  requestedDateTime?: any;
  requestedDateDay?: any;

  paymentMethod?: string;

  duration: any;
  distance: any;
  dateType: any;
  dateTypeDate: any;
  dateTypeTime: any;
  dateTypeTimestamp: any;
  vehicleType: any;
  vehicleTypeName?: any;
  freeFields?: any = [];
  freeFieldsConfig?: any = [];
  // addressArray?: any;
  passengerArray?: any;
  taxi?: any;

  returnTrip?: Ride;
}
