import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../models/user';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthenticationService} from '../services/authentication.service';
import {Router, ActivatedRoute} from '@angular/router';
import {CoolLocalStorage} from '@angular-cool/storage';
import {CookieService} from 'ngx-cookie';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {Md5} from 'ts-md5/dist/md5';

declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: '../templates/login.component.html',
  styleUrls: ['../sass/login.component.scss'],
  providers: [AuthenticationService]
})

export class LoginComponent implements OnInit, OnDestroy {
  public dashboardSettings: any = {
    companySettings: {},
    corporateSettings: {}
  };
  form: FormGroup;
  cookieKey = 'corporateDashboardLogin';
  model: any = {};
  returnUrl: string;
  users: User[];
  loginHash: string;
  action: string;

  user = new User();
  submitted = false;
  loginByIp = false;
  ipLoginError = false;
  companyId = '';

  constructor(
    private router: Router,
    private localStorage: CoolLocalStorage,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
  ) {
    const params: any = this.activatedRoute.snapshot.params;
    this.loginHash = localStorage.getItem('corporateHash');
    this.action = params.action ? params.action : 'login';
    this.cookieKey = `cd.${this.activatedRoute.snapshot.url.join('.')}`;
    this.loginByIp = environment.loginByIp;
    this.companyId = params.companyId || '';

    if (typeof(params.corporateHash) === 'undefined' && typeof(this.loginHash) === 'undefined') {
      // this._router.navigate(['**']);
    }

    this.loginHash = (params.corporateHash && params.corporateHash !== 'null' ?
      params.corporateHash : false);

    this.getConfigValues();
  }

  getConfigValues(hash?: string, cb?: () => void): void {
    let settings;
    if (hash) {
      settings = this.authenticationService.getSettings(hash);
    } else {
      settings = this.loginHash ?
        this.authenticationService.getSettings(this.loginHash) :
        this.authenticationService.getCompanySettings((this.companyId !== '' ? this.companyId : environment.loginByIpCompany));
    }
    settings.subscribe((dashboardConfig: any) => {
      this.dashboardSettings = dashboardConfig;
      this.dashboardSettings.companySettings.decoded_currency_char = decodeURIComponent(dashboardConfig.companySettings.currency_char);
      this.localStorage.setItem('dashboardSettings', JSON.stringify(dashboardConfig));
      /**
       * Set the app locale
       */
      this.translate.use(dashboardConfig.corporateSettings.locale);
      if (cb) { cb(); }
    });
  }

  setAction(action: string) {
    this.action = action;
  }

  ngOnInit() {
    $('body').addClass('login');
    const loginCookie = this.cookieService.get(this.cookieKey);

    if (typeof loginCookie !== 'undefined' && loginCookie !== '' && loginCookie != null ) {
      const parsedLoginCookie = JSON.parse(loginCookie);
      this.model.email = parsedLoginCookie.email;
      this.model.password = parsedLoginCookie.password;
      this.model.remember = 'on';
    }

    this.form = this.formBuilder.group({
      email: [this.model.email, []],
      password: [this.model.password, []],
      resetPassword: [this.model.email, []],
      remember: [this.model.remember, []],
    });

    // reset login status
    this.authenticationService.logout();

    if (this.companyId !== '') {
      console.log('SETTING LOCAL STORAGE', this.companyId)
      this.localStorage.setItem('companyId', this.companyId);
    }
    this.localStorage.setItem('corporateHash', this.loginHash);

    if (environment.loginByIp && this.companyId === '') {
      this.authenticationService.getUserIp().subscribe(ipData => {
        // console.log(`[LoginComponent.ipData]:`, ipData);
        const md5 = new Md5();
        const checksum = md5.appendStr(
          `${environment.loginByIpSalt}${ipData.ip}${environment.loginByIpCompany}`
        ).end();
        this.authenticationService.loginByIp(
          ipData.ip,
          checksum,
          environment.loginByIpCompany
        ).subscribe(user => {
          // console.log(`[LoginComponent.user]:`, user);
          this.localStorage.setItem('id_token', user.id_token);
          this.localStorage.setItem('currentUser', JSON.stringify(user));
          this.getConfigValues(user.hash, () => {
            this.router.navigate(['/ride/new']);
          });
        }, error => {
          console.log(`[LoginComponent.error]: `, error);
          this.ipLoginError = true;
        });
      }, error => {
        console.log(`[LoginComponent.error]: `, error);
        this.ipLoginError = true;
      });
    }

    // get return url from route parameters or default to '/'
    this.returnUrl = '/login';
  }
  ngOnDestroy() {
    $('body').removeClass('login');
  }

  onSubmit() {
    const self = this;
    this.submitted = true;
    if (this.action === 'login') {
      this.authenticationService
        .login(this.dashboardSettings.corporateSettings.businessClientId, this.form.value.email, this.form.value.password, this.companyId)
        .subscribe(user => {
          this.user = user;
          if (this.user && this.user.email) {
            this.localStorage.setItem('id_token', this.user.id_token);
            this.localStorage.setItem('currentUser', JSON.stringify(this.user));

            if (this.form.value.remember) {
              const cookieData = {
                email: this.user.email,
                password: this.form.value.password
              };
              this.cookieService.put(this.cookieKey, JSON.stringify(cookieData));
            } else {
              this.cookieService.remove(this.cookieKey);
            }

            if (this.companyId !== '') {
              this.authenticationService.getSettings(user.hash).subscribe((dashboardConfig: any) => {
                console.log('DASHBOARD SETTINGS!', dashboardConfig);
                this.dashboardSettings = dashboardConfig;
                this.dashboardSettings.companySettings.decoded_currency_char = decodeURIComponent(
                  dashboardConfig.companySettings.currency_char
                );
                this.localStorage.setItem('dashboardSettings', JSON.stringify(dashboardConfig));
                /**
                 * Set the app locale
                 */
                this.translate.use(dashboardConfig.corporateSettings.locale);
                this.router.navigate(['/dashboard']);
              });
            } else {
              this.router.navigate(['/dashboard']);
            }
          } else {
            this.router.navigate(['/login' + this.loginHash]);
          }
        }, (error => {
          console.error(error);
          /**
           * First get the tags then show the result
           */
          self.translate.get(['login_result_failed_title', 'login_result_failed_text']).subscribe(data => {
            swal({
              title: data.login_result_failed_title,
              text: data.login_result_failed_text,
              type: 'warning',
              confirmButtonText: 'Ok'
            });
          });
        }));
    } else {
      console.log(this.form.value);
      this.authenticationService.reset(
        this.dashboardSettings.corporateSettings.businessClientId,
        this.form.value.resetPassword,
        this.companyId
      ).subscribe(() => {
        self.translate.get(['login_reset_success_title', 'login_reset_success_text']).subscribe(data => {
          swal({
            title: data.login_reset_success_title,
            text: data.login_reset_success_text,
            type: 'success',
            confirmButtonText: 'Ok'
          }, function(){
            self.action = 'login';
          });
        });
      }, (error => {
        console.log(`[LoginComponent]: error`, error);
        /**
         * First get the tags then show the result
         */
        self.translate.get(['login_reset_failed_title', 'login_reset_failed_text']).subscribe(data => {
          swal({
            title: data.login_reset_failed_title,
            text: data.login_reset_failed_text,
            type: 'warning',
            confirmButtonText: 'Ok'
          });
        });
      }));
    }
  }
}
