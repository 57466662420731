import {Component, OnDestroy, AfterViewInit, ElementRef, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {DepartmentService} from '../services/department.service';
import {User} from '../models/user';
import {Department} from '../models/department';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../services/utility.service';

declare var $: any;
declare var google: any;
declare var accent_fold: any;
declare var swal: any;

@Component({
  selector: 'app-department-detail',
  templateUrl: '../templates/department-detail.component.html',
  styleUrls: ['../sass/department-detail.component.scss'],
  providers: [DepartmentService]
})
export class DepartmentDetailComponent implements OnDestroy, AfterViewInit, OnInit {
  @Input() addressValue = '';
  @Input() address: any = {
    'type': '',
    'iconUrl': 'assets/images/icon-location-departure.svg'
  };
  public departmentSubmitted = false;
  public department: Department = {};
  private id: number;
  currentUser: User;

  lat = 52.368035;
  lng = 4.953667;
  dashboardSettings: any;
  acData: any;

  constructor(
    private _router: Router,
    private _departmentService: DepartmentService,
    private _activatedRoute: ActivatedRoute,
    private _localStorage: CoolLocalStorage,
    private _translate: TranslateService,
    private _thisElement: ElementRef) {
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    this.currentUser = JSON.parse(_localStorage.getItem('currentUser'));
    _translate.use(this.dashboardSettings.corporateSettings.locale);

    const params: any = this._activatedRoute.snapshot.params;
    this.id = params.id;

    if (this.id > 0) {
      this.loadDepartment(this.id);
    }
  };

  loadDepartment(id: number) {
    const self = this;
    this._departmentService.getDepartment(id).subscribe(department => {
      this.department = department;
      this.addressValue = department.address;
      self.lat = this.department.lat;
      self.address.lat = this.department.lat;
      self.lng = this.department.lng;
      self.address.lng = this.department.lng;
    }, () => {});
  }

  submitDepartment() {
    let errors = false;

    this.departmentSubmitted = true;
    this.department.businessClientId = this.currentUser.businessClientId;
    this.department.address = this.addressValue;
    this.department.lat = this.address.lat;
    this.department.lng = this.address.lng;

    /**
     * Validate fields
     */
    $('.field').removeClass('error');

    /**
     * Check if we have a name
     */
    if (typeof this.department.name === 'undefined' || this.department.name === '' || this.department.name === null) {
      $('.field.name').addClass('error required');
      errors = true;
    }

    /**
     * Check if we have an address
     */
    if (typeof this.department.address === 'undefined' || this.department.address === '' || this.department.address === null) {
      $('.field.address').addClass('error required');
      errors = true;
    }

    // console.log(`[DepartmentDetailComponent.submitDepartment]: this.department`, this.department);

    /**
     * Submit the data if we have no errors
     */
    if (!errors) {
      this._departmentService.upsertDepartment(this.department).subscribe(department => {
        this.department = department;
        const self = this;
        self._translate.get(['department_created_title', 'department_created_text']).subscribe(translations => {
          swal({
            title: UtilityService.ucFirst(translations.department_created_title),
            text: UtilityService.ucFirst(translations.department_created_text).formatUnicorn(self.department.name || ''),
            type: 'success',
            confirmButtonText: 'Ok',
            html: true
          },
          function(){
            self._router.navigate(['/departments']);
          });
        });
      });
    }
  }

  deleteDepartment(id: number, name: string) {
    this.departmentSubmitted = true;
    const self = this;
    self._translate.get([
      'delete_department_title',
      'delete_department_text',
      'delete_department_cancel_button',
      'delete_department_confirm_button'
    ]).subscribe(t => {
      swal({
        title: UtilityService.ucFirst(t.delete_department_title),
        text: UtilityService.ucFirst(t.delete_department_text).formatUnicorn(name),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: UtilityService.ucFirst(t.delete_department_cancel_button),
        confirmButtonText: UtilityService.ucFirst(t.delete_department_confirm_button),
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        html: true
      },
      function(){
        self._departmentService.deleteDepartment(id).subscribe(() => {
          self._translate.get(['department_deleted_title', 'department_deleted_text']).subscribe(tt => {
            swal({
              title: UtilityService.ucFirst(tt.department_deleted_title),
              text: UtilityService.ucFirst(tt.department_deleted_text).formatUnicorn(name),
              type: 'success',
              confirmButtonText: 'Ok',
              html: true
            },
            function(){
              self._router.navigate(['/departments']);
            });
          });
        });
      });
    });
  }

  parsePoi (input, poiArray, setType) {
    for (let i = 0; i < poiArray.length; i++) {
      poiArray[i].value = `${poiArray[i].label} ${accent_fold(poiArray[i].label)} ${poiArray[i].poiCode}`;
    }
    const term = $.ui.autocomplete.escapeRegex(input.toLowerCase()
        .replace('station ', '')
        .replace('station', '')
        .replace('ns ', '')
        .replace('ns', ''))
      // Create two regular expressions, one to find suggestions starting with the user's input:
      , startsWithMatcher = new RegExp('^' + term, 'i')
      , startsWith = $.grep(poiArray, function(value) {
        return startsWithMatcher.test(value.value);
      })
      // ... And another to find suggestions that just contain the user's input:
      , containsMatcher = new RegExp(term, 'i')
      , contains = $.grep(poiArray, function (value) {
        return $.inArray(value, startsWith) < 0 &&
          containsMatcher.test(value.value);
      });

    const poi = [];
    if (startsWith.length > 0) {
      $.each(startsWith, function(i, matched) {
        if (poi.length < 5) {
          poi.push({
            type: setType,
            label: '' + matched.label,
            value: matched.label,
            poiCode: matched.poiCode,
            locationId: matched.locationId,
            lat: matched.lat,
            lng: matched.lng
          });
        }
      });
    }

    if (contains.length > 0) {
      $.each(contains, function(i, matched) {
        if (poi.length < 5) {
          poi.push({
            type: setType,
            label: '' + matched.label,
            value: matched.label,
            poiCode: matched.poiCode,
            locationId: matched.locationId,
            lat: matched.lat,
            lng: matched.lng,
          });
        }
      });
    }

    return poi;
  }

  ngOnInit() {
    const baseOffset = 65;
    const totalOffset = ( baseOffset + $('.breadcrumb').innerHeight() );
    $('#map').css('height', ($(window).innerHeight() - totalOffset ) + 'px');
    $('.left').css('height', ($(window).innerHeight() - totalOffset ) + 'px');
  }

  ngOnDestroy() {
    $('#map').html('');
  }

  ngAfterViewInit() {
    const self = this;
    $(this._thisElement.nativeElement).find('#address-input').autocomplete({
      source: function(request: any, response: any){
        let resultsLoaded = false;
        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions({
          input: request.term,
          componentRestrictions: {
            'country': self.dashboardSettings.corporateSettings.googleMapsCountries
          }
        }, function(predictions: any, status: any) {
          self.acData = [];

          if (typeof(self.dashboardSettings.pois['favorites']) !== 'undefined') {
            self.acData = self.acData.concat(self.parsePoi(request.term, self.dashboardSettings.pois['favorites'], 'favorite'));
          }

          if (typeof(self.dashboardSettings.pois['airports']) !== 'undefined') {
            self.acData = self.acData.concat(self.parsePoi(request.term, self.dashboardSettings.pois['airports'], 'airport'));
          }

          if (typeof(self.dashboardSettings.pois['nsStations']) !== 'undefined') {
            self.acData = self.acData.concat(self.parsePoi(request.term, self.dashboardSettings.pois['nsStations'], 'station'));
          }
          // if(typeof(availableCustomPois) != 'undefined'){
          //     poi = poi.concat(parsePoi($('#dep_int'),availableCustomPois,'custom'));
          // }

          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const googleResult = $.map(predictions, function(prediction: any) {
              return {
                type: 'location',
                label: '' + prediction.description + '',
                placeId: prediction.place_id,
                value: prediction.description
              }
            });

            if (googleResult.length > 0) {
              $.each(googleResult, function(i: any, matched: any) {
                self.acData.push({
                  type: 'location',
                  label: '' + matched.label,
                  placeId: matched.placeId,
                  value: matched.value
                });
              });
              response(self.acData);
              resultsLoaded = true;
            } else {
              response(self.acData);
              resultsLoaded = true;
            }
          } else {
            response(self.acData);
            resultsLoaded = true;
          }
        });
      },
      select: function( event: any, ui: any ){
        self.addressValue = ui.item.value;
        self.address.description = ui.item.value;
        self.address.type = ui.item.type;
        self.address.locationId = ui.item.locationId;

        if (ui.item.type === 'location') {
          self.address.placeId = ui.item.placeId;

          const geocoder = new google.maps.Geocoder;
          geocoder.geocode({'placeId': ui.item.placeId}, function(results, status) {
            if (status === 'OK') {
              if (results[0]) {
                self.address.lat = results[0].geometry.location.lat();
                self.lat = results[0].geometry.location.lat();
                self.address.lng = results[0].geometry.location.lng();
                self.lng = results[0].geometry.location.lng();
              }
            }
          });
        } else {
          self.address.poiCode = ui.item.poiCode;
          self.address.lat = ui.item.lat;
          self.lat = ui.item.lat;
          self.address.lng = ui.item.lng;
          self.lng = ui.item.lng;
        }
      }
    }).autocomplete( 'instance' )._renderItem = function( ul: any, item: any ) {
      return $( '<li class="' + item.type + '">' ).append( '<div class="icon">' + item.value + '</div>' ).appendTo( ul );
    };

    setTimeout(function(){
      resizeMap();
    }, 1000);

    $(window).on('resize', function(){
      resizeMap();
    });

    function resizeMap() {
      const baseOffset = 65;
      const totalOffset = ( baseOffset + $('.breadcrumb').innerHeight() );
      $('#map').css('height', ( $(window).innerHeight() - totalOffset ) + 'px');
      $('.left').css('height', ( $(window).innerHeight() - totalOffset ) + 'px');
    }
  }
}
