import {Component, OnDestroy, AfterViewInit, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RidesService} from '../services/rides.service';
import {DashboardService} from '../services/dashboard.service';
import Timer = NodeJS.Timer;
import {User} from '../models/user';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: '../templates/dashboard.component.html',
  styleUrls: ['../sass/dashboard.component.scss'],
  providers: [RidesService, DashboardService]
})

export class DashboardComponent implements OnDestroy, AfterViewInit, OnInit {
  public hasRides = false;
  public rides: any[];

  public filterQuery = '';

  taxiMarkers: marker[] = [];
  rideMarkers: marker[] = [];
  activeTaxiMarkers: marker[] = [];

  private readonly intervalId: Timer;
  private readonly rideIntervalId: Timer;

  currentUser: User;
  dashboardSettings: any;
  hasConnectedNumber = false;
  hasDriver = false;

  title = 'My first angular2-google-maps project';

  static resizeMap() {
    const baseOffset = 65;
    const search = $('.search');
    const table = $('.table');
    const totalOffset = ( baseOffset + ( search.is(':visible') ? ( search.innerHeight() + table.innerHeight() ) : 0 ) );
    const newMapHeight = ( $(window).innerHeight() - totalOffset );
    $('#map').css('height', newMapHeight + 'px');
  }

  constructor(private router: Router,
              private dashboardService: DashboardService,
              private ridesService: RidesService,
              private localStorage: CoolLocalStorage,
              private translate: TranslateService) {
    this.dashboardSettings = JSON.parse(localStorage.getItem('dashboardSettings'));
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    translate.use(this.dashboardSettings.corporateSettings.locale);

    if (this.dashboardSettings.orderSettings.showCarsOnMap) {
      this.getAvailableTaxis();
      this.intervalId = setInterval(() => { this.getAvailableTaxis(); }, 1000 * 30);
    }

    this.getRides();
    this.rideIntervalId = setInterval(() => { this.getRides(); }, 1000 * 60);
  }

  onDepartmentChanged(event: any): void {
    // console.log('[DashboardComponent]: onDepartmentChanged()');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getRides();
  }

  getRides() {
    const self = this;
    this.ridesService.getRides(this.currentUser.id, this.currentUser.businessClientId, (this.currentUser.scope && this.currentUser.scope.type !== 'personal' ? this.currentUser.department.id : this.currentUser.scope.klantId), 'dashboard').subscribe(rides => {
        this.rides = rides;
        this.hasRides = true;

        this.rideMarkers = [];
        for (let x = 0; x < rides.length; x++) {
          this.taxiMarkers.push({
            lat: rides[x].depLat,
            lng: rides[x].depLng,
            draggable: false,
            iconUrl: 'assets/images/icon-location-departure.svg'
          });
        }

        this.activeTaxiMarkers = [];

        rides.forEach((item) => {
          if (typeof(item.taxi) !== 'undefined') {
            self.activeTaxiMarkers.push({
              lat: item.taxi.lat,
              lng: item.taxi.lng,
              draggable: false,
              iconUrl: 'assets/images/icon-taxi-active.svg'
            });
          }

          if (item.connectedRideId) {
            self.hasConnectedNumber = true;
          }
          if (item.taxi) {
            self.hasDriver = true;
          }
        });

        for (let x = 0; x < rides.length; x++) {
          this.taxiMarkers.push({
            lat: rides[x].depLat,
            lng: rides[x].depLng,
            draggable: false,
            iconUrl: 'assets/images/icon-location-departure.svg'
          });
        }

        DashboardComponent.resizeMap();

      }, (error) => {
        /**
         * No active rides at the moment
         */
        if (error.status === 404) {
          this.hasRides = false;
        }
        this.rides = [];
      }
    );
  }

  getAvailableTaxis() {
    this.dashboardService.getAvailableTaxis(this.currentUser.businessClientId).subscribe(taxis => {
      this.taxiMarkers = [];
      for (let x = 0; x < taxis.length; x++) {
        this.taxiMarkers.push({
          lat: taxis[x].lat,
          lng: taxis[x].lng,
          draggable: false,
          iconUrl: 'assets/images/icon-taxi.svg'
        });
      }
    }, (error) => {
      /**
       * No active rides at the moment
       */
      if (error.status === 404) {

      }
    });
  }

  ngOnInit() {
    DashboardComponent.resizeMap();
  }

  ngOnDestroy() {
    $('#map').html('');
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.rideIntervalId) {
      clearInterval(this.rideIntervalId);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      DashboardComponent.resizeMap();
    }, 500);

    $(window).on('resize', () => {
      DashboardComponent.resizeMap();
    });
  }

  openRide(rideId: number, $event) {
    if ($event.srcElement === 'a.phoneNumber-link' || $event.target.className === 'phoneNumber-link') {
      return;
    }
    this.router.navigate([`/ride/${rideId}`]);
  }
}
