import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../models/user';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class UserService {
  headers = null;
  langCode = 'en';

  constructor(private _http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authentication', 'F6G8znpFVgu')
      .set('X-Api-Key', 'F6G8znpFVgu');
  };

  getCorporateUser(id: number): Observable<any> {
    return this._http.post(environment.apiUrl + '/getCorporateUser', { id }, { headers: this.headers });
  }

  getCorporateUsers(businessClientId: number, type: string): Observable<User[]> {
    const data = { businessClientId: businessClientId, type: type };
    return this._http.post<User[]>(environment.apiUrl + '/getCorporateUsers', data, { headers: this.headers });
  }

  upsertCorporateUser(user: User): Observable<User> {
    return this._http.post<User>(environment.apiUrl + '/upsertCorporateUser', user, { headers: this.headers });
  }

  deleteCorporateUser(id: number): Observable<any> {
    return this._http.post<any>(environment.apiUrl + '/deleteCorporateUser', { id }, { headers: this.headers });
  }

  generatePassword(): Observable<User> {
    const data = { langCode: this.langCode };
    return this._http.post<User>(environment.apiUrl + '/getLanguageFile', data, { headers: this.headers });
  }
}
