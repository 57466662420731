import {Component, AfterViewInit, Input} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-modal',
  templateUrl: '../templates/modal.component.html',
  styleUrls: ['../sass/modal.component.scss']
})
export class ModalComponent implements AfterViewInit {
  @Input() modalId = 'default';

  ngAfterViewInit() {
    const self = this;
    $('#' + self.modalId).on('change', function() {
      if ($(this).is(':checked')) {
        $('body').addClass('modal-open');
      } else {
        $('body').removeClass('modal-open');
      }
    });

    $('.modal-fade-screen, .modal-close').on('click', function() {
      $('.modal-state:checked').prop('checked', false).change();
    });

    $('.modal-inner').on('click', function(e: any) {
      e.stopPropagation();
    });
  }
}
