import {Component, OnDestroy, AfterViewInit, OnInit} from '@angular/core';
import {RidesService} from '../services/rides.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RatingService} from '../services/rating.service';
import {ComplaintService} from '../services/complaint.service';
import {Rating} from '../models/rating';
import {Complaint} from '../models/complaint';
import {Ride} from '../models/ride';
import {User} from '../models/user';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {AuthenticationService} from '../services/authentication.service';
import {UtilityService} from '../services/utility.service';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-ride-detail',
  templateUrl: '../templates/ride-detail.component.html',
  styleUrls: ['../sass/ride-detail.component.scss'],
  providers: [RidesService, RatingService, ComplaintService, AuthenticationService]
})

export class RideDetailComponent implements OnDestroy, AfterViewInit, OnInit {
  private rideId: number;
  rideHash: string;
  private action: string;
  public ride: Ride = new Ride();
  public rating: Rating = {rideId: 1, rating: 1};
  public complaint: Complaint = {rideId: 1};
  public complaintHandled = false;
  public anonymous = true;
  public stopInterval = false;

  public possibleRatings = [{r: 1}, {r: 2}, {r: 3}, {r: 4}, {r: 5}];

  public ratingSubmitted = false;
  public complainedSubmitted = false;

  model: any = {};
  dashboardSettings: any;
  currentUser: User;
  lat = 52.368035;
  lng = 4.953667;
  destination = { lng: 0, lat: 0 };
  activeTaxiMarkers: marker[] = [];
  allowCancel = true;
  String: any;

  translations: any;

  editLink = '';
  connectedEditStatusBlockers = ['canceled', 'cancelled', 'completed', 'client_pickup', 'active', 'arrived'];
  blockConnectedEdit = true;
  allowEdit = false;

  static in_array(needle, haystack, argStrict) {
    //  discuss at: http://locutus.io/php/in_array/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // improved by: vlado houba
    // improved by: Jonas Sciangula Street (Joni2Back)
    //    input by: Billy
    // bugfixed by: Brett Zamir (http://brett-zamir.me)
    //   example 1: in_array('van', ['Kevin', 'van', 'Zonneveld'])
    //   returns 1: true
    //   example 2: in_array('vlado', {0: 'Kevin', vlado: 'van', 1: 'Zonneveld'})
    //   returns 2: false
    //   example 3: in_array(1, ['1', '2', '3'])
    //   example 3: in_array(1, ['1', '2', '3'], false)
    //   returns 3: true
    //   returns 3: true
    //   example 4: in_array(1, ['1', '2', '3'], true)
    //   returns 4: false
    let key = '';
    const strict = !!argStrict;
    // we prevent the double check (strict && arr[key] === ndl) || (!strict && arr[key] === ndl)
    // in just one for, in order to improve the performance
    // deciding wich type of comparation will do before walk array
    if (strict) {
      for (key in haystack) {
        if (haystack.hasOwnProperty(key) && haystack[key] === needle) {
          return true;
        }
      }
    } else {
      for (key in haystack) {
        if (haystack.hasOwnProperty(key) && haystack[key] === needle) { // eslint-disable-line eqeqeq
          return true;
        }
      }
    }
    return false;
  }

  constructor(private _router: Router,
               private _ridesService: RidesService,
               private _authenticationService: AuthenticationService,
               private _localStorage: CoolLocalStorage,
               private _ratingService: RatingService,
               private _complaintService: ComplaintService,
               private _activatedRoute: ActivatedRoute,
               private _translate: TranslateService) {
    const params: any = this._activatedRoute.snapshot.params;

    this.String = String;
    this.rideId = params.id;
    this.rideHash = params.hash;
    this.action = params.action;
    this.ride.addressArray = [];
    this.complaint.reportingMethod = 'Corporate Dashboard';

    // console.log(`[RideDetailComponentconstructor]: this`, this);

    if (typeof params.hash !== 'undefined') {
      this.anonymous = true;
      this._authenticationService.getSettings(params.hash).subscribe((dashboardConfig: any) => {
        this.dashboardSettings = dashboardConfig;
        this._localStorage.setItem('dashboardSettings', JSON.stringify(dashboardConfig));
        // console.log(`[RideDetailComponentconstructor]: dashboardSettings`, this.dashboardSettings);

        _translate.use(dashboardConfig.corporateSettings.locale);

        // console.log(`[RideDetailComponent]: this.rideId`, this.rideId);

        this.loadRide(this.rideId);
        if(this.dashboardSettings && this.dashboardSettings.companySettings.allowEdit ===  'y') {
          this.allowEdit = true;
        }
      });
    } else {
      this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
      _translate.use(this.dashboardSettings.corporateSettings.locale);
      _translate.get([
        'cancel_ride_title',
        'cancel_ride_text',
        'cancel_ride_cancel_button',
        'cancel_ride_confirm_button',
        'ride_canceled_title',
        'ride_canceled_text',
        'ride_cancel_error_title',
        'ride_cancel_error_text',
        'cancel_connected_ride_title',
        'cancel_connected_ride_text',
        'edit_connected_ride_warning_title',
        'edit_connected_ride_warning_text',
        'edit_connected_ride_cancel_button',
        'edit_connected_ride_confirm_button',
      ]).subscribe(translations => {
        this.translations = translations;
        this.loadRide(this.rideId);
      });
      if(this.dashboardSettings && this.dashboardSettings.companySettings.allowEdit ===  'y') {
        this.allowEdit = true;
      }
    }
  }

  loadRide(id: number) {
    const self = this;
    // console.log(`[RideDetailComponentloadRide]: id`, id);

    if (typeof this.rideHash === 'undefined' || this.rideHash === '' || this.rideHash === null) {
      this._ridesService.getRide(id).subscribe(myRide => {
        self.processLoadedRide(myRide);
      }, error => {
        console.error(error);
      });
    } else {
      // console.log('Got a hash, loading', '|', 'ID:', id, '|', 'HASH:', this.rideHash);
      this._ridesService.getHashRide(id, this.rideHash).subscribe(myRide => {
        self.processLoadedRide(myRide);
      }, error => {
        console.error(error);
      });
    }
  }

  processLoadedRide( ride ) {
    const self = this;
    this.ride = ride;
    this.editLink = `/ride/${ride.id}/edit`;

    // Determine the edit mode for this ride
    if (ride.connectedRideId) {
      if (ride.firstInReturn || (!ride.firstInReturn && !this.connectedEditStatusBlockers.includes(ride.firstInReturnStatus))) {
        this.blockConnectedEdit = false;
      }
    }

    // Don't reload the ride data when the ride has any of these statuses
    const reloadBlacklist = [
      'completed',
      'canceled',
      'cancelled'
    ];

    const datePartArray = ride.requestedDate.split('-');
    const timePartArray = ride.requestedDateTime.split(':');
    const requestedDate = new Date(datePartArray[2], (datePartArray[1] - 1), datePartArray[0], timePartArray[0], timePartArray[1]);
    const minute = 60 * 1000;
    const threshold = this.dashboardSettings.orderSettings.cancelTime * minute;
    const noCancelDate = new Date(requestedDate.getTime() - threshold);
    const now = new Date();

    if (now > noCancelDate) {
      this.allowCancel = false;
    }

    // If ride status is not on reload blacklist, set a timeout to reload data
    if (!RideDetailComponent.in_array(this.ride.status, reloadBlacklist, '') && !this.stopInterval) {
      setTimeout(function(){
        self.loadRide(self.rideId);
        console.log('Reloading!');
      }, 30000);
    }

    this.activeTaxiMarkers = [];
    if (typeof(ride.taxi) !== 'undefined') {
      this.activeTaxiMarkers.push({
        lat: ride.taxi.lat,
        lng: ride.taxi.lng,
        draggable: false,
        iconUrl: 'assets/images/icon-taxi-active.svg'
      });
    }

    /**
     * Fill the ratingForm for this ride if there is data
     */
    if (ride.rating != null) {
      this._ratingService.getRatings(this.ride.id).subscribe(rating => {
        this.rating = rating;
      });

      const displayRating = $('select[name="displayRating"]');

      /**
       * Set the barrating stars
       */
      displayRating.barrating({
        theme: 'css-stars small',
        readonly: true
      });

      displayRating.barrating('set', ride.rating);
      $('select[name="rating"]').barrating('set', ride.rating);
    } else {
      this.rating = {rideId: ride.id, rating: 1};
    }

    /**
     * Fill the complainForm for this ride if there is data
     */
    // console.log(`[RideDetailComponentprocessLoadedRide]: ride`, ride);
    if (typeof ride.complainDesc !== 'undefined' && ride.complainDesc !== null) {
      this._complaintService.getComplaint(self.rideId).subscribe(complaint => {
        this.complaint = complaint;
        if (this.complaint.dateHandled !== null && this.complaint.dateHandled !== '') {
          self.complaintHandled = true;
        }
      });
    } else {
      if (typeof this.currentUser !== 'undefined') {
        this.complaint.reporter = this.currentUser.firstName + ' ' + this.currentUser.lastName;
      }
    }
  }

  editConnectedRide() {
    const self = this;
    swal({
      title: self.translations.edit_connected_ride_warning_title,
      text: self.translations.edit_connected_ride_warning_text,
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: UtilityService.ucFirst(self.translations.edit_connected_ride_cancel_button),
      confirmButtonText: UtilityService.ucFirst(self.translations.edit_connected_ride_confirm_button),
      closeOnConfirm: true
    },
    () => {
      if (self.ride.firstInReturn) {
        self._router.navigate([`/ride/${self.ride.id}/edit`]);
      } else {
        self._router.navigate([`/ride/${self.ride.connectedRideId}/edit`]);
      }
    });
  }

  /**
   * Process the rating form and send to the API
   */
  submitRating() {
    this.ratingSubmitted = true;
    this.rating.rideId = this.rideId;
    this.rating.rating = $('#ratingSelect').val();

    this._ratingService.upsertRatings(this.rating).subscribe(() => {
      this._translate.get(['rating_saved_title', 'rating_saved_text']).subscribe(data => {
        swal(UtilityService.ucFirst(data.rating_saved_title), UtilityService.ucFirst(data.rating_saved_text), 'success');
        this.loadRide(this.rideId);
      });
    });
  }

  /**
   * Process the complaint form and send to the API
   */
  submitComplain() {
    this.complainedSubmitted = true;
    this.complaint.rideId = this.rideId;

    this._complaintService.upsertComplaints(this.complaint).subscribe(() => {
      this._translate.get(['complaint_saved_title', 'complaint_saved_text']).subscribe(data => {
        swal(UtilityService.ucFirst(data.complaint_saved_title), UtilityService.ucFirst(data.complaint_saved_text), 'success');
        this.loadRide(this.rideId);
      });
    });
  }

  cancelRide(rideId, connectedRide = false) {
    const self = this;
    const title = UtilityService.ucFirst(
      self.translations[(connectedRide ? 'cancel_connected_ride_title' : 'cancel_ride_title')]
    ).formatUnicorn(rideId);
    const text = UtilityService.ucFirst(self.translations[(connectedRide ? 'cancel_connected_ride_text' : 'cancel_ride_text')]);
    swal({
      title,
      text,
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: UtilityService.ucFirst(self.translations.cancel_ride_cancel_button),
      confirmButtonText: UtilityService.ucFirst(self.translations.cancel_ride_confirm_button),
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    },
    () => {
      self._ridesService.cancelRide(self.dashboardSettings.companySettings.companyId, rideId).subscribe(() => {
        if (self.ride.connectedRideId && self.ride.connectedCancelAllow && !connectedRide) {
          self.cancelRide(self.ride.connectedRideId, true);
        } else {
          swal({
              title: UtilityService.ucFirst(self.translations.ride_canceled_title),
              text: UtilityService.ucFirst(self.translations.ride_canceled_text),
              type: 'success',
              confirmButtonText: 'Ok'
            },
            () => {
              self._router.navigate(['/dashboard']);
            });
        }
      }, error => {
        swal({
          title: UtilityService.ucFirst(self.translations.ride_cancel_error_title).formatUnicorn(error.status),
          text: UtilityService.ucFirst(self.translations.ride_cancel_error_text),
          type: 'error',
          confirmButtonText: 'Ok'
        }, () => {
        });
      });
    });
  }

  ngOnInit() {
    const el = $('.search');
    const baseOffset = 65;
    const totalOffset = (baseOffset + (el.is(':visible') ? (el.innerHeight() + $('.table').innerHeight()) : 0));
    $('#map').css('height', ($(window).innerHeight() - totalOffset) + 'px');
  }

  ngOnDestroy() {
    $('#map').html('');
    this.stopInterval = true;
  }

  ngAfterViewInit() {
    const self = this;
    const complaintForm = $('form.complaint');
    const complaintTrigger = $('.complaint', '.left');
    const complaintCloser = $('.close-form', complaintForm);

    const ratingForm = $('form.rating');
    const ratingTrigger = $('.rating', '.tools');
    const ratingCloser = $('.close-form', ratingForm);

    const rightPanel = $('section.right');

    complaintTrigger.on('click', function(){
      complaintForm.addClass('active');
      complaintTrigger.addClass('active');
      rightPanel.addClass('active');
      ratingForm.removeClass('active');
      ratingTrigger.removeClass('active');
    });

    complaintCloser.on('click', function(){
      complaintForm.removeClass('active');
      complaintTrigger.removeClass('active');
      rightPanel.removeClass('active');
    });

    ratingTrigger.on('click', function(){
      ratingForm.addClass('active');
      ratingTrigger.addClass('active');
      rightPanel.addClass('active');
      complaintForm.removeClass('active');
      complaintTrigger.removeClass('active');
    });

    ratingCloser.on('click', function(){
      ratingForm.removeClass('active');
      ratingTrigger.removeClass('active');
      rightPanel.removeClass('active');
    });

    if (this.action === 'complaint') {
      complaintTrigger.click();
    }

    $('#ratingSelect').barrating({
      theme: 'css-stars'
    });

    $('select', '.field.select').select2({
      minimumResultsForSearch: Infinity
    }).trigger('change');

    $('select', '.field.type').on('select2:select', function(evt) {
      const field = $('#' + evt.target.id);
      self.complaint.type = field.val();
    });

    setTimeout(function(){
      resizeMap();
      $('select').change();
    }, 500);

    $(window).on('resize', function(){
      resizeMap();
    });

    function resizeMap() {
      const baseOffset = 65;
      const totalOffset = ( baseOffset + $('.breadcrumb').innerHeight() );
      $('#map').css('height', ($(window).innerHeight() - totalOffset) + 'px');
      $('.complaint', '.right').css('height', ($(window).innerHeight() - totalOffset) + 'px');
      $('.rating', '.right').css('height', ($(window).innerHeight() - totalOffset) + 'px');
      $('section.left').css('height', ($(window).innerHeight() - totalOffset) + 'px');
      if ($(window).innerWidth() <= 414) {
        $('section.right').css('height', ($(window).innerHeight() - totalOffset) + 'px');
      }
    }
  }
}
