import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Department} from '../models/department';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class DepartmentService {
  headers = null;

  constructor(private _http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authentication', 'F6G8znpFVgu')
      .set('X-Api-Key', 'F6G8znpFVgu');
  };

  getDepartment (id: number): Observable<any> {
    return this._http.post(environment.apiUrl + '/getDepartment', { id : id }, { headers: this.headers });
  }

  getDepartments (businessClientId: number): Observable<Department[]> {
    const data = { businessClientId: businessClientId };
    return this._http.post<Department[]>(environment.apiUrl + '/getDepartments', data, { headers: this.headers });
  }

  upsertDepartment (department: Department): Observable<Department> {
    return this._http.post<Department>(environment.apiUrl + '/upsertDepartment', department, { headers: this.headers });
  }

  deleteDepartment (id: number): Observable<any> {
    return this._http.post(environment.apiUrl + '/deleteDepartment', { id : id }, { headers: this.headers });
  }
}
