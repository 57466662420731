import {
  Component,
  AfterViewInit,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import {AddressFieldComponent} from './address-field.component';
import {User} from '../models/user';
import {Ride} from '../models/ride';
import {UUID} from 'angular2-uuid';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../services/utility.service';
import {FieldStatus} from '../models/field-status';

declare var $: any;
declare var sortable: any;
declare var google: any;

@Component({
  selector: 'app-sortable-addresses',
  templateUrl: '../templates/sortable-addresses.component.html',
  styleUrls: ['../sass/sortable-addresses.component.scss'],
  entryComponents: [ AddressFieldComponent ]
})
export class SortableAddressesComponent implements AfterViewInit, OnInit {
  @ViewChild(AddressFieldComponent, {static: false, read: ViewContainerRef}) container: ViewContainerRef;
  @ViewChild('departureLocation', {static: false}) departureAddress: AddressFieldComponent;
  @Input() disabled = false;
  @Input() currentUser: User;
  @Input() ride: Ride;
  @Input() calculatePrice: any;
  @Output() onValueChanged: EventEmitter<object> = new EventEmitter();
  startAddress = '';
  id: any;
  dashboardSettings: any;
  letGoString: string;
  departureString: string;
  destinationString: string;
  viaString: string;
  optionalString: string;
  formType = 'default';
  orderType = 'default';
  status: FieldStatus = new FieldStatus();

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private localStorage: CoolLocalStorage,
              private translate: TranslateService,
              private viewContainerRef: ViewContainerRef) {
    this.dashboardSettings = JSON.parse(localStorage.getItem('dashboardSettings'));
    this.formType = this.dashboardSettings.orderSettings.formType || 'default';
    this.orderType = this.dashboardSettings.orderSettings.orderType || 'default';
    translate.use(this.dashboardSettings.corporateSettings.locale);
    translate.get(['address_let_go', 'departure', 'destination', 'via', 'optional', 'mandatory']).subscribe(data => {
      this.letGoString = UtilityService.ucFirst(data.address_let_go);
      this.departureString = UtilityService.ucFirst(data.departure);
      this.destinationString = UtilityService.ucFirst(data.destination);
      this.viaString = UtilityService.ucFirst(data.via);

      this.dashboardSettings = JSON.parse(localStorage.getItem('dashboardSettings'));
      if (!this.dashboardSettings.orderSettings.mandatoryDestinationAddress) {
        this.optionalString = UtilityService.ucFirst(data.optional);
      } else {
        this.optionalString = UtilityService.ucFirst(data.mandatory);

      }
    });
  }

  ngOnInit(): void {
    this.validate();
  }

  addAddress(i: any) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(AddressFieldComponent);
    const ref = this.container.createComponent(factory);

    ref.instance.currentUser = this.currentUser;
    ref.instance.disabled = this.disabled;
    ref.instance.ride = this.ride;
    ref.instance.componentReference = ref;
    ref.instance.calculatePrice = this.calculatePrice;
    ref.instance.valueChanged.subscribe((data) => this.validate(data));
    ref.instance.index = i;

    if (i === 1 && this.ride.addressArray.length < 2) {
      ref.instance.address = {
        uuid: ref.instance.id
      };
      this.ride.addressArray.push(ref.instance.address);
    } else if (i == null) {
      ref.instance.address = {
        uuid: ref.instance.id
      };
      ref.instance.index = this.ride.addressArray.length;
      this.ride.addressArray.push(ref.instance.address);
    } else {
      ref.instance.address = this.ride.addressArray[i];
      ref.instance.value = this.ride.addressArray[i].description;
      this.ride.addressArray[i].uuid = ref.instance.id;
    }
    ref.changeDetectorRef.detectChanges();
    this.reStyleAddressFields();
    this.validate();
  }

  reStyleAddressFields() {
    const fields = $('.field.sort', '.sortable.addresses');
    const count = fields.length;
    const self = this;

    if (!self.departureString) {
      setTimeout(() => { self.reStyleAddressFields(); }, 100);
      return false;
    }
    fields.each((i: any, el: any) => {
      const handle = $('.handle', el);
      const placeHolder = $('.placeholder', el);
      const input = $('input', el);
      handle.removeClass('red green');
      if ( i === 0 ) {
        placeHolder.html(`${self.departureString} *`);
        input.attr('placeholder', '');
        self.ride.addressArray[i].iconUrl = 'assets/images/icon-location-departure.svg';
        $(el).removeClass('departure destination');
        $(el).addClass('departure');
        handle.addClass('red');
      } else if ( i >= ( count - 1 ) ) {
        placeHolder.html(`${self.destinationString}`);
        input.attr('placeholder', `${self.optionalString}`);
        self.ride.addressArray[i].iconUrl = 'assets/images/icon-location-destination.svg';
        $(el).removeClass('departure destination');
        $(el).addClass('destination');
        handle.addClass('green');
      } else {
        placeHolder.html(`${self.viaString} *`);
        input.attr('placeholder', '');
        self.ride.addressArray[i].iconUrl = 'assets/images/icon-location-on-route.svg';
        $(el).removeClass('departure destination');
      }
      handle.html(String.fromCharCode(97 + i).toUpperCase()).css('opacity', 1);
    });

    $('body').trigger('click');
  }

  initSortable() {
    const addressSortable = $('.sortable.addresses');
    const self = this;
    addressSortable.sortable({
      placeholder: 'fake-field',
      handle: '.handle',
      start: (event: any, ui: any) => {
        const fields = $('.field.sort', ui.item.parent() );
        fields.each((i: any, el: any) => {
          $('.placeholder', el).html('...');
          $(ui.item).find('.placeholder').first().html(`${self.letGoString}`);
          $('.handle', el).css('opacity', 0);
        });
      },
      stop: () => {
        /**
         * Resort the ride.addressArray
         */
        const newAddressArray = [];
        const fields = $('.field.sort', '.sortable.addresses');

        fields.each((i: any, el: any) => {
          self.ride.addressArray.forEach((item) => {
            if ($(el).attr('id') === item.uuid) {
              const newItem = item;
              newItem.version = UUID.UUID();
              newAddressArray[i] = newItem;
            }
          });
        });
        self.ride.addressArray = newAddressArray;
        self.validate();
        self.reStyleAddressFields();
      }
    });
  }

  geocodePlaceId(placeId, next) {
      if (!placeId) { return next(); }
      const geocoder = new google.maps.Geocoder();
      return geocoder.geocode({placeId}, (results, status) => {
          if (status === 'OK') {
              let address: any;
              address = {};
              if (results[0].address_components) {
                  for (let i = 0; i < results[0].address_components.length; i++) {
                      const comp = results[0].address_components[i];
                      if (comp.types[0] === 'postal_code' || comp.types[0] === 'postal_code_prefix') {
                          address.postcode = comp.short_name.replace(' ', '');
                      }
                      if (comp.types[0] === 'locality') {
                          address.city = comp.short_name;
                      }
                      if (comp.types[0] === 'country') {
                          address.country = comp.short_name;
                      }
                      if (comp.types[0] === 'route') {
                          address.address = comp.short_name;
                      }
                      if (comp.types[0] === 'street_number') {
                          address.housenumber = comp.short_name.replace(' ', '');
                      }
                  }

                  address.longitude = results[0].geometry.location.lng();
                  address.latitude = results[0].geometry.location.lat();
              }
              return next(JSON.stringify(address));
          } else {
              window.alert('Geocoder failed due to: ' + status);
          }
      });
  }

  validate(value?: any) {
    if (this.formType === 'airport') {
      const departure = this.ride.addressArray[0];
      const destination = this.ride.addressArray[this.ride.addressArray.length - 1];
      const status = new FieldStatus();

      // console.log('departure', departure);
      // console.log('destination', destination);

      if (
        (departure.type === 'airport' || departure.type === 'luchthaven' || (departure.poiCode ? departure.poiCode : '').startsWith('AIR')) ||
        (destination.type === 'airport' || departure.type === 'luchthaven' || (destination.poiCode ? destination.poiCode : '').startsWith('AIR'))
      ) {
        status.valid = true;
        status.message = '';
      } else {
        status.valid = false;
        status.message = 'destination_airport_required';
      }

      this.status = status;
      this.onValueChanged.emit(status);
    }
    this.reStyleAddressFields();
  }

  ngAfterViewInit() {
    const self = this;
    this.departureAddress.calculatePrice = this.calculatePrice;
    if (typeof(google) === 'undefined') {
      setTimeout(() => { self.ngAfterViewInit(); }, 100);
      return false;
    }

    if (typeof(this.ride.id) === 'undefined' && typeof(this.ride.routeId) === 'undefined') {
      this.departureAddress.address.lat = this.currentUser.department.lat;
      this.departureAddress.address.lng = this.currentUser.department.lng;
      const geocoder = new google.maps.Geocoder();

      const results = JSON.parse(this.localStorage.getItem('cache-'+this.currentUser.department.address));

      if(!results) {
        geocoder.geocode({address: this.currentUser.department.address}, (results, status) => {
          if (status === 'OK') {
            this.localStorage.setItem('cache-'+this.currentUser.department.address, JSON.stringify(results));

            if (results[0]) {
              self.departureAddress.address.lat = results[0].geometry.location.lat();
              self.departureAddress.address.lng = results[0].geometry.location.lng();
              self.departureAddress.address.placeId = results[0].place_id;
              self.departureAddress.address.description = results[0].formatted_address;
              self.departureAddress.address.iconUrl = 'assets/images/icon-location-departure.svg';
              self.departureAddress.address.type = 'location';
              self.departureAddress.address.uuid = self.departureAddress.id;
              self.departureAddress.value = results[0].formatted_address;

              let addressCode: any;
              addressCode = {};
              if (results[0].address_components) {
                for (let i = 0; i < results[0].address_components.length; i++) {
                  const comp = results[0].address_components[i];
                  if (comp.types[0] === 'postal_code' || comp.types[0] === 'postal_code_prefix') {
                    addressCode.postcode = comp.short_name.replace(' ', '');
                  }
                  if (comp.types[0] === 'locality') {
                    addressCode.city = comp.short_name;
                  }
                  if (comp.types[0] === 'country') {
                    addressCode.country = comp.short_name;
                  }
                  if (comp.types[0] === 'route') {
                    addressCode.address = comp.short_name;
                  }
                  if (comp.types[0] === 'street_number') {
                    addressCode.housenumber = comp.short_name.replace(' ', '');
                  }
                }

                addressCode.lng = results[0].geometry.location.lng();
                addressCode.lat = results[0].geometry.location.lat();
                self.departureAddress.address.housenumber = addressCode.housenumber;
              }
              $('input', '#' + self.departureAddress.id).trigger('change');
            }
          }

          /**
           * Init destination input
           */
          self.addAddress(1);

          self.initSortable();
          self.reStyleAddressFields();
          self.validate();
          self.ride.addressArray[0] = self.departureAddress.address;
        });
      } else {
        self.departureAddress.address.lat = results[0].geometry.location.lat;
        self.departureAddress.address.lng = results[0].geometry.location.lng;
        self.departureAddress.address.placeId = results[0].place_id;
        self.departureAddress.address.description = results[0].formatted_address;
        self.departureAddress.address.iconUrl = 'assets/images/icon-location-departure.svg';
        self.departureAddress.address.type = 'location';
        self.departureAddress.address.uuid = self.departureAddress.id;
        self.departureAddress.value = results[0].formatted_address;

        let addressCode: any;
        addressCode = {};
        if (results[0].address_components) {
          for (let i = 0; i < results[0].address_components.length; i++) {
            const comp = results[0].address_components[i];
            if (comp.types[0] === 'postal_code' || comp.types[0] === 'postal_code_prefix') {
              addressCode.postcode = comp.short_name.replace(' ', '');
            }
            if (comp.types[0] === 'locality') {
              addressCode.city = comp.short_name;
            }
            if (comp.types[0] === 'country') {
              addressCode.country = comp.short_name;
            }
            if (comp.types[0] === 'route') {
              addressCode.address = comp.short_name;
            }
            if (comp.types[0] === 'street_number') {
              addressCode.housenumber = comp.short_name.replace(' ', '');
            }
          }

          addressCode.lng = results[0].geometry.location.lng
          addressCode.lat = results[0].geometry.location.lat
          self.departureAddress.address.housenumber = addressCode.housenumber;
        }
        $('input', '#' + self.departureAddress.id).trigger('change');
        self.addAddress(1);
        self.initSortable();
        self.reStyleAddressFields();
        self.validate();
        self.ride.addressArray[0] = self.departureAddress.address;
      }
    } else {
      const fields = $('.field.sort', '.sortable.addresses');
      this.ride.addressArray[0].uuid = $(fields[0]).attr('id');
      $(this.ride.addressArray).each((i) => {
        if (i > 0) {
          self.addAddress(i);
        }
      });

      if (self.ride.addressArray[0].type === 'location' || self.ride.addressArray[0].type === '') {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({address: this.ride.addressArray[0].description}, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              self.departureAddress.address.lat = results[0].geometry.location.lat();
              self.departureAddress.address.lng = results[0].geometry.location.lng();
              self.departureAddress.address.placeId = results[0].place_id;
              self.departureAddress.address.description = results[0].formatted_address;
              self.departureAddress.address.type = 'location';
              self.departureAddress.value = results[0].formatted_address;

              let addressCode: any;
              addressCode = {};
              if (results[0].address_components) {
                  for (let i = 0; i < results[0].address_components.length; i++) {
                      const comp = results[0].address_components[i];
                      if (comp.types[0] === 'postal_code' || comp.types[0] === 'postal_code_prefix') {
                          addressCode.postcode = comp.short_name.replace(' ', '');
                      }
                      if (comp.types[0] === 'locality') {
                          addressCode.city = comp.short_name;
                      }
                      if (comp.types[0] === 'country') {
                          addressCode.country = comp.short_name;
                      }
                      if (comp.types[0] === 'route') {
                          addressCode.address = comp.short_name;
                      }
                      if (comp.types[0] === 'street_number') {
                          addressCode.housenumber = comp.short_name.replace(' ', '');
                      }
                  }

                  addressCode.lng = results[0].geometry.location.lng();
                  addressCode.lat = results[0].geometry.location.lat();
              }
              self.departureAddress.address.addressCode = JSON.stringify(addressCode);

              $('input', '#' + self.departureAddress.id).trigger('change');
            }
          }
        });
      } else {
        /**
         * do this in a timeout, because we send this var through to the address component
         */
        setTimeout(() => {
          self.startAddress = self.ride.addressArray[0].description;
          self.departureAddress.address = self.ride.addressArray[0];
        }, 100);
      }

      this.initSortable();
      this.reStyleAddressFields();
      // this.validate();
    }

    if (typeof self.ride.addressArray[1] !== 'undefined' && typeof self.ride.addressArray[1].type !== 'undefined') {
      $('.tools', 'sortable-addresses').addClass('active');
    }
  }
}
