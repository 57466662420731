import {Component, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../models/user';
import {ComplaintService} from '../services/complaint.service';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';

declare var $: any;

@Component({
  selector: 'app-complaints',
  templateUrl: '../templates/complaints.component.html',
  styleUrls: ['../sass/complaints.component.scss'],
  providers: [ComplaintService]
})
export class ComplaintsComponent implements AfterViewInit {
  public complaints: any[];
  public filterQuery = '';
  dashboardSettings: any;
  currentUser: User;

  constructor (private router: Router,
               private _localStorage: CoolLocalStorage,
               private complaintService: ComplaintService,
               private translate: TranslateService) {
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    this.currentUser = JSON.parse(_localStorage.getItem('currentUser'));
    translate.use(this.dashboardSettings.corporateSettings.locale);
    this.getComplaints();
  }
  getComplaints() {
    this.complaintService.getComplaints(this.currentUser.department.id).subscribe(complaints => {
      this.complaints = complaints;
    });
  }

  openRideWithComplaint(rideId) {
    this.router.navigate([`/ride/${rideId}/complaint`]);
  }

  ngAfterViewInit() {
    resizeTable();

    $(window).on('resize', function(){
      resizeTable();
    });

    function resizeTable() {
      const baseOffset = 65;
      const totalOffset = ( baseOffset + $('.search').innerHeight() );
      const table = $('.table');

      if (table.innerHeight() > ($(window).innerHeight() - totalOffset)) {
        table.css('height', ($(window).innerHeight() - totalOffset) + 'px');
      }
    }
  }
}
