import {Component, AfterViewInit, Input} from '@angular/core';
import {Ride} from '../models/ride';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TranslateService} from '@ngx-translate/core';
import { RideComponent } from './ride.component';

declare var $: any;

@Component({
  selector: 'app-passengers-field',
  templateUrl: '../templates/passengers-field.component.html',
  styleUrls: ['../sass/passengers-field.component.scss']
})
export class PassengersFieldComponent implements AfterViewInit {
  @Input() disabled = false;
  @Input() ride: Ride;
  modalId = 'passengers-modal';
  totalPassengers = 0;
  maxPassengers: number;
  allowedPassengers = [];
  passengersText: string;
  dashboardSettings: any;
  mandatoryMarker: string;
  @Input() parent!: RideComponent;

  constructor(private _localStorage: CoolLocalStorage, private _translate: TranslateService) {
    this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
    this.maxPassengers = this.dashboardSettings.orderSettings.maxPassengers;
    this.allowedPassengers = new Array(this.maxPassengers);
    _translate.use(this.dashboardSettings.corporateSettings.locale);
    _translate.get(['add_passengers_text']).subscribe(translations => {
      this.passengersText = translations.add_passengers_text;
    });
    if (this.dashboardSettings.orderSettings.mandatoryName) {
      this.mandatoryMarker = '*';
    }
    if (this.dashboardSettings.orderSettings.mandatoryPhoneNumber) {
      this.mandatoryMarker = '*';
    }
  }

  public setMaxPassengers(amount) {
    this.maxPassengers = amount;
    this.allowedPassengers = new Array(this.maxPassengers);
  }

  ngAfterViewInit() {
    const self = this;
    const tabUi = $('#tabs');
    const addTab = $('.add-tab', tabUi);

    $('select', '.field.select').select2({
      minimumResultsForSearch: Infinity
    });

    $('select').on('select2:select', function (evt) {
      // console.log('select2:select general field');
      const field = $('#' + evt.target.id);
      self.ride[evt.target.id] = field.val();
    });

    /**
     * Init tabs
     */
    tabUi.tabs();

    /**
     * Make this reference after tabs have been initialised
     */
    const tabContainer = $('ul.ui-tabs-nav', tabUi);

    /**
     * Add click functionality for adding new tabs/passengers
     */
    addTab.on('click', function(e: any){
      e.preventDefault();
      self.totalPassengers++;
      // console.log('Add new tab! totalPassengers:', self.totalPassengers);

      tabContainer.append('<li><a [routerLink]="" fragment="#tabs-' + self.totalPassengers + '">#' + self.totalPassengers + '</a></li>');

      tabUi.tabs('refresh');
    });
  }

  openModal() {
    $('#' + this.modalId).prop('checked', true).change();
  }
}
