import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { CoolStorageModule } from '@angular-cool/storage';
import { DataTableModule } from 'angular2-datatable';

import { DataFilterPipe } from './pipes/data-filter.pipe';

import { DirectionsMapDirective } from './directives/directions-map.directive';
import { DirectionsMapWaypoints } from './directives/directions-map.waypoints';
import { AppComponent } from './app.component';
import { AuthGuard } from './authentication/auth.guard';
import { DashboardComponent } from './components/dashboard.component';
import { LoginComponent } from './components/login.component';
import { PageNotFoundComponent } from './components/page-not-found.component';
import { MainNavigationComponent } from './components/main-navigation.component';
import { RidesComponent } from './components/rides.component';
import { PeopleComponent } from './components/people.component';
import { RideDetailComponent } from './components/ride-detail.component';
import { PersonDetailComponent } from './components/person-detail.component';
import { DepartmentsComponent } from './components/departments.component';
import { DepartmentDetailComponent } from './components/department-detail.component';
import { RideComponent } from './components/ride.component';
import { ModalComponent } from './components/modal.component';
import { SortableAddressesComponent } from './components/sortable-addresses.component';
import { AddressFieldComponent } from './components/address-field.component';
import { PassengersFieldComponent } from './components/passengers-field.component';
import { PassengersFieldFormComponent } from './components/passengers-field-form.component';
import { RoutesComponent } from './components/routes.component';
import { FavoritesComponent } from './components/favorites.component';
import { ComplaintsComponent } from './components/complaints.component';
import { ExportRidesComponent } from './components/export-rides.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UcFirstPipe } from './pipes/uc-first.pipe';
import { CookieModule } from 'ngx-cookie';
import { AgmCoreModule } from '@agm/core';
import { FormatPricePipe } from './pipes/format-price.pipe';
import {MomentModule} from 'ngx-moment';
import { HtmlPipe } from './pipes/html.pipe';
import { environment } from '../environments/environment';

const appRoutes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login/company/:companyId', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:corporateHash', component: LoginComponent },
  { path: 'login/:corporateHash/:action', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'rides', component: RidesComponent, canActivate: [AuthGuard] },
  { path: 'ride/new', component: RideComponent, canActivate: [AuthGuard] },
  { path: 'ride/new/:routeId', component: RideComponent, canActivate: [AuthGuard] },
  { path: 'ride/:id', component: RideDetailComponent, canActivate: [AuthGuard] },
  { path: 'ride/:id/h/:hash', component: RideDetailComponent },
  { path: 'ride/:id/edit', component: RideComponent, canActivate: [AuthGuard] },
  { path: 'ride/:id/:action', component: RideDetailComponent, canActivate: [AuthGuard] },
  { path: 'routes', component: RoutesComponent, canActivate: [AuthGuard] },
  { path: 'favorites', component: FavoritesComponent, canActivate: [AuthGuard] },
  { path: 'complaints', component: ComplaintsComponent, canActivate: [AuthGuard] },
  { path: 'people', component: PeopleComponent, canActivate: [AuthGuard] },
  { path: 'people/new', component: PersonDetailComponent, canActivate: [AuthGuard] },
  { path: 'person/:id', component: PersonDetailComponent, canActivate: [AuthGuard] },
  { path: 'departments', component: DepartmentsComponent, canActivate: [AuthGuard] },
  { path: 'department/new', component: DepartmentDetailComponent, canActivate: [AuthGuard] },
  { path: 'department/:id', component: DepartmentDetailComponent, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent }
];

export function createTranslateLoader(http: HttpClient) {
  const projectId = (environment.translationProjectId === '' ? '5a8bd989b34517372a415bc5' : environment.translationProjectId);
  return new TranslateHttpLoader(http, `https://localization-api.dispatchapi.io/api/Projects/${projectId}/`, '');
  // return new TranslateHttpLoader(http, environment.apiUrl + '/../../v2/corporate/getLanguageFile?lang=', '');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    RidesComponent,
    RideComponent,
    RideDetailComponent,
    RoutesComponent,
    FavoritesComponent,
    ComplaintsComponent,
    PeopleComponent,
    PersonDetailComponent,
    DepartmentsComponent,
    DepartmentDetailComponent,
    MainNavigationComponent,
    ModalComponent,
    ExportRidesComponent,
    SortableAddressesComponent,
    AddressFieldComponent,
    PassengersFieldComponent,
    PassengersFieldFormComponent,
    PageNotFoundComponent,
    DataFilterPipe,
    DirectionsMapDirective,
    DirectionsMapWaypoints,
    UcFirstPipe,
    FormatPricePipe,
    HtmlPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    CookieModule.forRoot(),
    CoolStorageModule,
    DataTableModule,
    MomentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['places']
    })
  ],
  providers: [
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('nl-NL');
  }
}
