import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {JwtHelper} from 'angular2-jwt';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';


@Injectable()
export class AuthGuard implements CanActivate {
  jwtHelper: JwtHelper = new JwtHelper();
  token = '';
  hash = '';

  constructor(private router: Router, private localStorage: CoolLocalStorage) {
    this.token = localStorage.getItem('id_token');
    this.hash = localStorage.getItem('corporateHash') || environment.loginByIpCompany;
  }

  canActivate() {
    // Check to see if a user has a valid JWT
    if (this.token && !this.jwtHelper.isTokenExpired(this.token)) {
      // If they do, return true and allow the user to load the home component
      return true;
    }
    const path = '/login/' + this.hash;

    console.log(`[AuthGuardcanActivate]: Token expired! Routing to ${path}`);
    // If not, they redirect them to the login page
    this.router.navigate([path]);
    return false;
  }
}
