import {Component, AfterViewInit, Input, EventEmitter, Output} from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {Department} from '../models/department';
import {DashboardService} from '../services/dashboard.service';
import {DepartmentService} from '../services/department.service';
import {User} from '../models/user';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {AuthenticationService} from '../services/authentication.service';
import { environment } from '../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-main-navigation',
  templateUrl: '../templates/main-navigation.component.html',
  styleUrls: ['../sass/main-navigation.component.scss'],
  providers: [DashboardService, DepartmentService, AuthenticationService]
})
export class MainNavigationComponent implements AfterViewInit {
  @Input() currentUser: User;
  @Output() departmentChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  rideHash: string;
  departments: Department[] = [];
  dashboardSettings: any = {
    corporateSettings: {}
  };
  loginByIp = false;
  activeDepartment = '';
  companyId = '';

  constructor(private _router: Router,
              private _localStorage: CoolLocalStorage,
              private _dashboardService: DashboardService,
              private _authenticationService: AuthenticationService,
              private _activatedRoute: ActivatedRoute,
              private _translate: TranslateService,
              private _departmentService: DepartmentService) {
    const params: any = this._activatedRoute.snapshot.params;
    this.rideHash = params.hash;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.companyId = this._localStorage.getItem('companyId') || '';
    this.loginByIp = environment.loginByIp;

    if (typeof params.hash !== 'undefined') {
      this._authenticationService.getSettings(params.hash).subscribe((dashboardConfig: any) => {
        this.dashboardSettings = dashboardConfig;
        this._localStorage.setItem('dashboardSettings', JSON.stringify(dashboardConfig));
        /**
         * Set the app locale
         */
        _translate.use(dashboardConfig.corporateSettings.locale);
      });
    } else {
      this.dashboardSettings = JSON.parse(_localStorage.getItem('dashboardSettings'));
      _translate.use(this.dashboardSettings.corporateSettings.locale);
    }

    _router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const mainMenu = $('nav.main');
        mainMenu.removeClass('active');
      }
    });

    if (this.currentUser != null) {
      this._dashboardService.getDepartments(this.currentUser.businessClientId).subscribe(department => {
        this.departments = department;
      }, (error => {
        console.error(error);
      }));

      this.activeDepartment = this.currentUser.department.name;
    }
  }

  switchDepartment(departmentId: number) {
    this._departmentService.getDepartment(departmentId).subscribe(department => {
      this.currentUser.department = department;
      this.activeDepartment = department.name;
      localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
      this.departmentChanged.emit(true);
    }, (error => {
      console.error(error);
    }));
  }

  ngAfterViewInit() {
    const mainMenu = $('nav.main');
    const mainMenuTrigger = $('.main-menu-trigger');
    const mainMenuCloser = $('.close-menu', mainMenu);
    const mainMenuCloseDiv = $('.main-menu-closer');

    mainMenuTrigger.on('click', function(e:any){
      e.preventDefault();
      mainMenu.addClass('active');
      mainMenuCloseDiv.addClass('active');
    });

    mainMenuCloser.on('click', function(e:any){
      e.preventDefault();
      mainMenu.removeClass('active');
      mainMenuCloseDiv.removeClass('active');
    });

    mainMenuCloseDiv.on('click', function(e:any){
      e.preventDefault();
      mainMenu.removeClass('active');
      mainMenuCloseDiv.removeClass('active');
    });
  }
}
