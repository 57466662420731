import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ucFirst'
})
export class UcFirstPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    //  discuss at: http://locutus.io/php/ucfirst/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // improved by: Brett Zamir (http://brett-zamir.me)
    //   example 1: ucfirst('kevin van zonneveld')
    //   returns 1: 'Kevin van zonneveld'
    value += ''
    var f = value.charAt(0).toUpperCase()
    return f + value.substr(1)
  }

}
