import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Ride} from '../models/ride';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Rating} from '../models/rating';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class RatingService {
  headers = null;
  ride: Ride;
  businessClientId: '';

  constructor(private _http: HttpClient, private _localStorage: CoolLocalStorage) {
    this.headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authentication', 'F6G8znpFVgu')
      .set('X-Api-Key', 'F6G8znpFVgu');
    this.businessClientId = JSON.parse(_localStorage.getItem('businessClientId'));
  };

  getRatings(rideId: number): Observable<Rating> {
    return this._http.post<Rating>(environment.apiUrl + '/getRatings', {rideId}, {headers: this.headers});
  }

  upsertRatings(rating: Rating): Observable<Rating> {
    return this._http.post<Rating>(environment.apiUrl + '/upsertRatings', rating, {headers: this.headers});
  }
}
