import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Address} from '../models/address';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class FavoriteService {
  headers = null;
  favorite: Address;

  constructor(private _http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authentication', 'F6G8znpFVgu')
      .set('X-Api-Key', 'F6G8znpFVgu');
  };

  getFavorites(businessClientId: number): Observable<any[]> {
    const data = { businessClientId: businessClientId };
    return this._http.post<any[]>(environment.apiUrl + '/getFavoriteAddresses', data, { headers: this.headers });
  }

  upsertFavorites(address: Address): Observable<Address> {
    return this._http.post<Address>(environment.apiUrl + '/upsertFavoriteAddress', address, { headers: this.headers });
  }

  deleteFavorite(id: number, businessClientId: number): Observable<any> {
    const data = { favoriteId: id, businessClientId: businessClientId };
    return this._http.post(environment.apiUrl + '/deleteFavoriteAddress', data, { headers: this.headers });
  }
}
